<template>
  <h4 class="card-title">¿Qué deseas hacer?</h4>
  <div class="row" style="gap: 15px;height: 60px">
    <button type="button" class="btn btn-outline-dark btn-sm btn-icon-text mb-5"
            data-toggle="modal" data-target="#modal-add-bank">
      <i class='bx bx-user-plus btn-icon-prepend'></i>
      Crear nuevo banco
    </button>
  </div>
  <div v-if="bankAll?.length > 0">
    <div class="row">
      <div class="col-sm-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <p class="card-title mb-0">Bancos</p>
            <div class="table-responsive">
              <table class="table table-striped table-borderless">
                <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Url Banco</th>
                  <th>Acciones</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(bank, index) in paginationData" :key="index">
                  <td >{{ bank.name }}</td>
                  <td ><p class="body-part-text">{{ bank.bankUrl }}</p></td>
                  <td>
                    <div class="dropdown">
                      <button class="btn btn-sm btn-danger dropdown-toggle m-0" type="button"
                              data-toggle="dropdown">
                        <i class='bx bx-wrench'></i>
                      </button>
                      <div class="dropdown-menu">
                        <h6 class="dropdown-header">¿Qué deseas hacer?</h6>
                        <button type="button" @click="bankDetail = bank" class="p-3 dropdown-item"
                                data-toggle="modal" data-target="#modal-details">
                          <i class='bx bx-search-alt align-middle'></i> &nbsp;&nbsp; Ver banco
                        </button>
                        <button type="button" @click="showEdit(bank)" class="p-3 dropdown-item"
                                data-toggle="modal" >
                          <i class='bx bx-search-alt align-middle'></i> &nbsp;&nbsp; Editar banco
                        </button>
                        <button type="button" @click="bankDetail = bank" class="p-3 dropdown-item"
                                data-toggle="modal" data-target="#modal-remove-bank">
                          <i class='bx bx-trash align-middle'></i> &nbsp;&nbsp; Eliminar banco
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <br/>
              <a-pagination v-model:current="currentPage"
                            v-model:page-size="pageSize"
                            :show-size-changer="false"
                            :total="bankAll.length"
                            :show-total="total => `${total} reg`"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    Sin registros para mostrar...
  </div>

  <modal-component id-modal="modal-details" title="Detalle del banco">
    <template v-slot:modal-body>
      <div class="card">
        <div class="card-body">
          <p class="card-title">{{ bankDetail?.name }}</p>
          <ul class="icon-data-list">
            <li>
              <div class="d-flex">
                <div>
                  <p class="text-info mb-1">{{ bankDetail?.bankUrl }}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <template v-slot:modal-footer>
      <button type="button" class="btn btn-primary" data-dismiss="modal">Ok</button>
    </template>
  </modal-component>
  <modal-component id-modal="modal-add-bank" title="Crear nuevo banco">
    <template v-slot:modal-body>
      <form @submit.prevent="addBank" ref="form-add-bank">
        <div class="form-row">
          <div class="col-sm-12 ">
            <label class="form-label">Nombre banco</label>
            <input type="text" class="form-control"
                   name="name"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model.trim="model.name">
          </div>
          <div class="col-sm-12 mt-3">
            <label class="form-label">Url Redirección</label>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class='bx bx-globe' ></i></span>
                </div>
                <input type="text"
                       class="form-control"
                       name="bankUrl"
                       data-required="true"
                       :data-required-msg="validatorMessages.REQUIRED"
                       :data-regex="regexValidator.URL"
                       data-regex-msg="Debe ingresar una URL."
                       v-model.trim="model.bankUrl">
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
    <template v-slot:modal-footer>
      <button type="submit" class="btn btn-primary" @click="addBank">Ok</button>
    </template>
  </modal-component>
  <modal-component id-modal="modal-remove-bank" title="Eliminar Banco">
    <template v-slot:modal-body>
      <h4 class="text-center">¿En verdad desea eliminar este registro?</h4>
    </template>
    <template v-slot:modal-footer>
      <button type="button" class="btn btn-primary" @click="removeBank">Ok</button>
    </template>
  </modal-component>
</template>

<script>
import httpService from "@/services/httpService";
import toastService from "@/services/toastService";
import modalComponent from "@/components/ModalComponent";
import validatorService, { validatorMessages, regexValidator } from "@/services/validatorService";
import modalService from "@/services/modalService";
import utilService from "@/services/utilService";

const fieldsToValidate = [
  "name",
  "bankUrl"
]
export default {
  name       : "BankView",
  components :{modalComponent},
  data:()=>({
    bankAll    : [],
    bankDetail : null,
    model      : { },
    currentPage : 1,
    pageSize    : 10,
    validatorMessages,
    regexValidator
  }),
  mounted() {
    this.getBankAll()
  },
  watch:{
    ...validatorService.init(fieldsToValidate)
  },
  computed:{
    paginationData(){
      return utilService.filterData(this.bankAll, this.currentPage, this.pageSize)
    }
  },
  methods:{
    getBankAll(){
      httpService.get("/Bank/GetAll", false)
          .then(res => this.bankAll = res?.data?.data)
          .catch(() => toastService.error())
    },
    resetForm(){
      this.$refs["form-add-bank"].reset()
    },
    addBank() {
      if (!validatorService.validateAll(fieldsToValidate)) return;
      const dataModel = {...this.model}
      const flagUpdate = this.model.guid !== undefined && this.model.guid !== ''
      const options = {
        method   :  flagUpdate ? httpService.types.PUT : httpService.types.POST,
        action : flagUpdate ? "/Bank/Update" : "/Bank/Create",
        data   : dataModel
      }
      httpService.execute(options)
          .then(() => {
            modalService.hideModal("modal-add-bank");
            this.getBankAll();
            this.model = {};
          })
          .catch(({response}) => toastService.error(response?.message))
    },
    showEdit(bank){
      this.resetForm()
      modalService.showModal('modal-add-bank');
      this.model = bank;
      validatorService.reset(this.model)
    },
    removeBank(){
      httpService.delete("Bank/Delete?guid=" + this.bankDetail.guid)
          .then(() => {
            modalService.hideModal("modal-remove-bank");
            this.getBankAll();
            this.model = {};
          })
    }
  }
}
</script>

<style scoped>
.body-part-text{
  width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
	}
</style>
