import {HttpTransportType, HubConnectionBuilder, LogLevel} from '@microsoft/signalr';

export default {
    connectSignalR() {
        return new HubConnectionBuilder()
            .withUrl(process.env.VUE_APP_SINGAL_R_URL, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets
            })
            .configureLogging(LogLevel.Information)
            .build();
    }

}
