export default {
    formatCurrency: value => value?.toLocaleString('es-CO', {
        style: 'currency',
        currency: 'COP',
    }),
    filterData: function (data, currentPage, pageSize) {
        return data.filter(function (item, i) {
            return i < currentPage * pageSize && i >= (currentPage * pageSize) - pageSize;
        });
    }
}
