<template>
  <nav-layout v-if="this.$route.name !== 'login' && this.$route.name !== 'restore-password'"/>
  <div v-else><router-view/></div>
  <loading-component/>
</template>

<style>
@import "@/assets/vendors/feather/feather.css";
@import "@/assets/vendors/ti-icons/css/themify-icons.css";
@import "@/assets/vendors/css/vendor.bundle.base.css";
@import "@/assets/vendors/datatables.net-bs4/dataTables.bootstrap4.css";
@import "@/assets/vendors/ti-icons/css/themify-icons.css";
@import "@/assets/js/select.dataTables.min.css";
@import "@/assets/css/vertical-layout-light/style.css";
</style>
<script>
import NavLayout from "@/components/NavLayout";
import LoadingComponent from "@/components/LoadingComponent";
import loadingService from "@/services/loadingService";
import signalRService from "@/services/signalRService";
import notificationSound from "@/assets/notifications/notification-sound-1.mp3"
import axios from "axios";
import httpService from "@/services/httpService";
import {mapMutations} from "vuex";

export default {
  components: {LoadingComponent, NavLayout},
  data: () => ({
    dataNotification: []
  }),
  mounted() {
    loadingService.show()
    setTimeout(() => loadingService.hide(), 1500)
    const requestGetDepartmentsAndCities = axios.get(httpService.URL_API_VIVA_APP + "/Settings/GetDepartmentsAndCities");
    const requestAgreementGetAll         = axios.get(httpService.URL_API_VIVA_APP + "/Agreement/GetAll");
    const requestPanicGetAll             = axios.get(httpService.URL_API_VIVA_APP + "/Panic/GetAll");
    const requestGetAllStateServices     = axios.get(httpService.URL_API_VIVA_APP + "/Settings/GetAllStateServices");
    const requestGetAllProfiles          = axios.get(httpService.URL_API_VIVA_APP + "/User/GetAllProfiles");
    const requestGetAllStateUser         = axios.get(httpService.URL_API_VIVA_APP + "/Settings/GetAllStateUser");
    axios.all([
      requestGetDepartmentsAndCities,
      requestAgreementGetAll,
      requestPanicGetAll,
      requestGetAllStateServices,
      requestGetAllProfiles,
      requestGetAllStateUser,
    ]).then(res => {
          this.SET_CITIES_AND_DEPARTMENTS(res[0]?.data?.data);
          this.SET_AGREEMENTS(res[1]?.data?.data);
          this.SET_ALL_NOTIFICATIONS(res[2]?.data?.data);
          this.SET_NOTIFICATIONS_LIST();
          this.SET_STATE_SERVICES(res[3]?.data?.data);
          this.SET_ALL_PROFILES(res[4]?.data?.data);
          this.SET_ALL_STATES_USER(res[5]?.data?.data);
        })
    this.handlerHub();
  },
  methods: {
    ...mapMutations(
        [
          'SET_CITIES_AND_DEPARTMENTS',
          'SET_AGREEMENTS',
          'SET_NOTIFICATIONS',
          'SET_ALL_NOTIFICATIONS',
          'SET_NOTIFICATIONS_LIST',
          'SET_STATE_SERVICES',
          'SET_CURRENT_PROFILE',
          'SET_ALL_PROFILES',
          'SET_ALL_STATES_USER',
        ]
    ),
    handlerHub() {
      const hub = signalRService.connectSignalR();
      hub.start()
          .then(() => console.log('Connection started.......!'))
          .catch(err => console.log({'Error while connect with server': err}));
      hub.on('AlertNotification', result => {
        this.SET_NOTIFICATIONS(result);
        this.SET_NOTIFICATIONS_LIST();
        const audio = new Audio(notificationSound)
        audio.play();
      })
    },
  },
}
</script>
