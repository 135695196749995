<template>
  <div class="container-scroller">
    <!-- partial:partials/_navbar.html -->
    <nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <router-link class="btn btn-link navbar-brand brand-logo mr-5" exact-active-class="''" :to="{ name : 'home' }">
          <img src="../assets/images/viva-app-logo.png" class="mr-2" alt="logo"/>
        </router-link>
        <router-link class="btn btn-link navbar-brand brand-logo-mini" exact-active-class="''" :to="{ name : 'home' }">
          <img src="../assets/images/viva-app-logo-simple.png" alt="logo"/>
        </router-link>
      </div>
      <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
        <ul class="navbar-nav navbar-nav-right">
          <li class="nav-item dropdown"  v-if="getNotifications?.length > 0">
            <a class="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="#" data-toggle="dropdown" @click="setJustNow(getNotifications)">
              <i class="icon-bell mx-0"></i>
              <span class="count"></span>
            </a>
            <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list">
              <p class="mb-0 font-weight-normal float-left dropdown-header">Notificaciones</p>
              <div v-for="(notification, index) in getNotifications.filter((data, index) => index <= 9)" :key="index" >
                <router-link class="dropdown-item preview-item" :to="{ name:'notifications', params: { id:notification.guid }  }" >
                  <div class="preview-thumbnail">
                    <div class="preview-icon bg-warning">
                      <i class="ti-info-alt mx-0"></i>
                    </div>
                  </div>
                  <div class="preview-item-content">
                    <h6 class="preview-subject font-weight-normal">
                      {{ getCustomer(notification.agreementGuid)?.registerNumber }} - {{ getCustomer(notification.agreementGuid)?.customer?.firstName }} {{ getCustomer(notification.agreementGuid)?.customer?.firstSurname }}
                    </h6>
                    <div class="font-weight-light small-text mb-0 text-muted">
                      {{ justNow.find(x => x.guid === notification.guid)?.time }}
                    </div>
                  </div>
                </router-link>
              </div>
              <p class="mb-0 font-weight-normal float-left dropdown-header">Total notificaciones: {{ getNotifications.length }} <RouterLink class="btn btn-link"  exact-active-class="btn-link" :to="{ name: 'notifications' }">Ver todas</RouterLink> </p>
            </div>
          </li>
          <li class="nav-item nav-profile dropdown">
            <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown">
              <img src="../assets/images/avatar.png" alt="profile"/>
            </a>
            <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
              <button class="dropdown-item" type="button" @click="logout">
                <i class="ti-power-off text-primary"></i>
                Logout
              </button>
            </div>
          </li>
        </ul>
        <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
          <span class="icon-menu"></span>
        </button>
      </div>
    </nav>
    <!-- partial -->
    <div class="container-fluid page-body-wrapper">
      <!-- partial -->
      <!-- partial:partials/_sidebar.html -->
      <nav class="sidebar sidebar-offcanvas" id="sidebar" style="width: 300px">
        <ul class="nav">
          <li class="nav-item">
            <router-link class="nav-link" to="/">
              <i class="icon-grid menu-icon"></i>
              <span class="menu-title">Inicio</span>
            </router-link>
          </li>
          <li class="nav-item" v-for="(view, index) in views.filter(x => x.state === 1 && x.profileGuid === getCurrentProfile.guid)" :key="index">
            <router-link class="nav-link" :to="view?.action" v-if="!view.isParent && view.parentGuid === null">
              <i :class="view.icon" ></i>
              &nbsp;&nbsp;&nbsp;
              <span class="menu-title">{{view.name}}</span>
            </router-link>
            <a class="nav-link collapsed" data-toggle="collapse" :href="'#parent-'+ index" v-if="view.isParent">
              <i :class="view.icon" ></i>
              &nbsp;&nbsp;&nbsp;
              <span class="menu-title">{{view.name}}</span>
            </a>
            <div class="collapse" :id="'parent-'+ index" style="">
              <ul class="nav flex-column sub-menu">
                <li class="nav-item" v-for="(submenu, sub_index) in views.filter(x => x.parentGuid === view.guid.toString().toUpperCase() && x.state === 1 && x.profileGuid === getCurrentProfile.guid)" :key="sub_index">
                  <router-link :to="submenu?.action" class="nav-link">{{submenu.name}}</router-link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </nav>
      <!-- partial -->
      <div class="main-panel">
        <div class="content-wrapper">
          <div class="row">
            <div class="col-md-12 grid-margin">
              <div class="row">
                <div class="col-12 col-xl-8 mb-4 mb-xl-0">
                  <h3 class="font-weight-bold">Bienvenid@ {{getUserInfo.firstName}} {{getUserInfo.firstSurname}} </h3>
                  <h6 class="font-weight-normal mb-0"> <span class="text-primary"></span></h6>
                </div>
                <div class="col-12 col-xl-4">
                  <div class="justify-content-end d-flex">
                    <div class="dropdown flex-md-grow-1 flex-xl-grow-0">
                      <button class="btn btn-sm btn-light bg-white" type="button">
                        {{getMoment().format("LLL")}}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <slot name="main">
            <router-view/>
          </slot>
        </div>
        <!-- content-wrapper ends -->
        <!-- partial:partials/_footer.html -->
        <footer class="footer">
          <div class="d-sm-flex justify-content-center justify-content-sm-between">
            <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © {{ new Date().getFullYear() }}.</span>
          </div>
          <div class="d-sm-flex justify-content-center justify-content-sm-between">
            <span class="text-muted text-center text-sm-left d-block d-sm-inline-block"><a href="https://www.themewagon.com/" target="_blank"> </a></span>
          </div>
        </footer>
        <!-- partial -->
      </div>
      <!-- main-panel ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
  <!-- container-scroller -->
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import dateTimeService from "@/services/dateTimeService";
import httpService from "@/services/httpService";

export default {
  name : "NavLayout",
  data :()=>({
    customers : [],
    justNow   : [],
    views     : []
  }),
  computed:{
    ...mapGetters(['getNotifications', 'getAgreements', 'getCurrentProfile', 'getUserInfo', 'getAllProfiles']),
  },
  beforeMount() {
    this.getViews();
  },
  methods:{
    ...mapMutations(['SET_AUTH']),
    getMoment(){
      return dateTimeService.getMoment()
    },
    getCustomer(agreementGuid) {
      return this.getAgreements.find(x => x.guid === agreementGuid)
    },
    setJustNow(notifications){
      this.justNow = notifications.map(item => ({...item, time: this.getMoment().unix() - item.time < 60 ? "Justo ahora" : item.date}))
    },
    getViews(){
      httpService.get("/Settings/GetAllViews", false)
          .then(({data:{data: views}}) => this.views = views);
    },
    logout(){
      this.SET_AUTH({})
      this.$router.push("login")
    },
  },
}
</script>

<style scoped>
  .active-link{
    background : #4B49A6;
    color      : white !important;
  }
  .nav-item .collapsed > .sidebar .nav .nav-item .nav-link i.menu-icon {
    border-radius : 8px 8px 0 0;
    background    : #4B49AC;
    color         : red !important;
  }
  .preview-list .preview-item .preview-thumbnail img, .preview-list .preview-item .preview-thumbnail .preview-icon {
    width         : 20px;
    height        : 20px;
    border-radius : 100%;
  }
</style>
