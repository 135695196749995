<template>
  <h4 class="card-title">Listado de servicios solicitados</h4>
  <div class="row" style="gap: 15px;height: 60px">

  </div>
  <div class="form-group col-sm-6 col-md-4">
    <div class="input-group">
      <input type="text" class="form-control" placeholder="Buscar..."
             @keyup="ev => filterSearch(ev, 'serviceName', 'customerName', 'customerName', 'statusName', 'identification', 'serviceHistoryAgreementRegisterNumber')">
      <div class="input-group-append">
        <button class="btn btn-sm btn-primary" type="button"><i class="bx bx-search"></i></button>
      </div>
    </div>
  </div>
  <div v-if="servicesHistoryAll?.length > 0">
    <div class="row">
      <div class="col-sm-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <p class="card-title mb-0">Servicios</p>
            <div class="table-responsive">
              <table class="table table-striped table-borderless">
                <thead>
                <tr>
                  <th>Servicio</th>
                  <th>Cliente</th>
                  <th>Documento</th>
                  <th>Número de matrícula</th>
                  <th>Estado del servicio</th>
                  <th>Fecha de solicitud</th>
                  <th>Acciones</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(service, index) in paginationData" :key="index">
                  <td >{{ service.serviceName }}</td>
                  <td >{{ service.customerName }}</td>
                  <td >{{ service.identification }}</td>
                  <td >{{ service.serviceHistoryAgreementRegisterNumber }}</td>
                  <td >{{ service.statusName }}</td>
                  <td >{{ service.serviceHistoryDateRequest }}</td>
                  <td>
                    <div class="dropdown">
                      <button class="btn btn-sm btn-danger dropdown-toggle m-0" type="button"
                              data-toggle="dropdown">
                        <i class='bx bx-wrench'></i>
                      </button>
                      <div class="dropdown-menu">
                        <h6 class="dropdown-header">¿Qué deseas hacer?</h6>
                        <button type="button" @click="servicesHistoryDetail = service" class="p-3 dropdown-item"
                                data-toggle="modal" data-target="#modal-details">
                          <i class='bx bx-search-alt align-middle'></i> &nbsp;&nbsp; Ver el servicio
                        </button>
                        <button type="button" @click="changeStatusServicesHistory('EEC5C640-D074-4355-8AB8-E77B42970423',service)" class="p-3 dropdown-item"
                                data-toggle="modal" >
                          <i class='bx bx-check align-middle'></i> &nbsp;&nbsp; Marcar como atendido
                        </button>
                        <button type="button" @click="changeStatusServicesHistory('603F9A15-0ADE-4B2C-B0DB-002ABED3B98D',service)" class="p-3 dropdown-item"
                                data-toggle="modal" >
                          <i class='bx bx-x-circle align-middle'></i> &nbsp;&nbsp; Marcar como cancelado
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <br/>
              <a-pagination v-model:current="currentPage"
                            v-model:page-size="pageSize"
                            :show-size-changer="false"
                            :total="servicesHistoryAll.length"
                            :show-total="total => `${total} reg`"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    Sin registros para mostrar...
  </div>

  <modal-component id-modal="modal-details" title="Detalle del servicio">
    <template v-slot:modal-body>
      <div class="card">
        <div class="card-body">
          <p class="card-title">{{ servicesHistoryDetail?.serviceName }}</p>
          <ul class="icon-data-list">
            <li>
              <div class="d-flex">
                <div>
                  <p class="text-info mb-1">{{ servicesHistoryDetail?.customerName }}</p>
                </div>
              </div>
            </li>
            <li>
              <div class="d-flex">
                <div>
                  <p class="text-info mb-1">{{ servicesHistoryDetail?.serviceHistoryAgreementRegisterNumber }}</p>
                </div>
              </div>
            </li>
            <li>
              <div class="d-flex">
                <div>
                  <p class="text-info mb-1">{{ servicesHistoryDetail?.statusName }}</p>
                </div>
              </div>
            </li>
            <li>
              <div class="d-flex">
                <div>
                  <p class="text-info mb-1">{{ servicesHistoryDetail?.serviceHistoryDateRequest }}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <template v-slot:modal-footer>
      <button type="button" class="btn btn-primary" data-dismiss="modal">Ok</button>
    </template>
  </modal-component>

</template>

<script>
import httpService from "@/services/httpService";
import toastService from "@/services/toastService";
import modalComponent from "@/components/ModalComponent";
import validatorService, { validatorMessages } from "@/services/validatorService";
import utilService from "@/services/utilService";
// import modalService from "@/services/modalService";

const fieldsToValidate = [
"customerName",
"serviceName",
"statusName",
"serviceHistoryGguid",
"serviceHistoryServiceGguid",
"serviceHistoryCustomerGguid",
"serviceHistoryAgreementRegisterNumber",
"serviceHistoryAgreementGguid",
"serviceHistoryDateRequest",
"serviceHistoryDateUpdate",
"serviceHistoryDateAttention",
"serviceHistoryState"
]
export default {
  name       : "ServicesHistoryView",
  components :{modalComponent},
  data:()=>({
    servicesHistoryAll         :[],
    servicesHistoryOriginalAll :[],
    servicesHistoryDetail      : null,
    model                      :{ },
    currentPage                : 1,
    pageSize                   : 10,
    validatorMessages
  }),
  mounted() {
    this.getServicesHistoryAll()
  },
  computed:{
    paginationData(){
      return utilService.filterData(this.servicesHistoryAll, this.currentPage, this.pageSize)
    }
  },
  watch:{
    ...validatorService.init(fieldsToValidate)
  },
  methods:{
    getServicesHistoryAll(){
      httpService.get("/HistoryService/GetAllHistoryServices", false)
          .then(res => {
            this.servicesHistoryAll         = res?.data?.data;
            this.servicesHistoryOriginalAll = res?.data?.data;
          })
          .catch(() => toastService.error())
    },
    changeStatusServicesHistory(status_id,serviceHistory) {
      const dataModel = {...serviceHistory}
      dataModel.serviceHistoryState = status_id

      httpService.put("HistoryService/Update", {...dataModel})
          .then(() => {

            this.getServicesHistoryAll();
          })

    },
    filterSearch(ev, ...args) {
      this.servicesHistoryAll = [...this.servicesHistoryOriginalAll];
      if (ev.target.value !== "") {
        let data = []
        args.forEach(arg => {
          this.servicesHistoryOriginalAll.forEach(x => {
            const dataResult = {...x}
            const argument = arg.split(".")
            argument.forEach(item => x = x[item]);
            x?.toString().toLowerCase().indexOf(ev.target.value.toString().toLowerCase()) > -1 &&
            data.push(dataResult)
          });
        });
        this.servicesHistoryAll = Array.from(new Set(data.map(JSON.stringify))).map(JSON.parse);
      }
    }
  }
}
</script>

<style scoped>

</style>
