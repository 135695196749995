<template>
  <div class="row">
    <div class="form-group col-sm-6 col-md-4">
      <div class="input-group">
        <input type="text" class="form-control" placeholder="Buscar..."
               @keyup="ev => filterSearch(ev, 'registerNumber', 'firstName', 'firstSurname', 'name', 'attendedTxt', 'description', 'pqr', 'identification')">
        <div class="input-group-append">
          <button class="btn btn-sm btn-primary" type="button"><i class="bx bx-search"></i></button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="pqrAll?.length > 0">
    <div class="row">
      <div class="col-sm-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <p class="card-title mb-0">PQRs</p>
            <div class="table-responsive">
              <table class="table table-striped table-borderless">
                <thead>
                <tr>
                  <th>Fecha Solicitud</th>
                  <th>Nombre</th>
                  <th>Documento</th>
                  <th>Numero de matrícula</th>
                  <th>Tipo de PQR</th>
                  <th>Respuesta</th>
                  <th>Usuario Atiende</th>
                  <th>Fecha De Atención</th>
                  <th>Estado</th>
                  <th>Acciones</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(pqr, index) in paginationData" :key="index">
                  <td >{{ dateTimeService.momentUtil(pqr.requestDate).locale("es").format("LLL") }}</td>
                  <td ><p class="body-part-text">{{ pqr.customer?.firstName }} {{ pqr.customer?.secondName }} {{ pqr.customer?.firstSurname }} {{ pqr.customer?.secondSurname }}</p></td>
                  <td >{{ pqr.customer?.identification }}</td>
                  <td >{{ pqr.registerNumber }}</td>
                  <td >{{ pqr.pqrType?.name }}</td>
                  <td >{{ pqr.attendedTxt }}</td>
                  <td >{{ getNameResponseUser(pqr.userAttended) }}</td>
                  <td >{{ pqr.responseDate }}</td>
                  <td >{{ pqr.stateServices?.name }}</td>
                  <td>
                    <div class="dropdown">
                      <button class="btn btn-sm btn-danger dropdown-toggle m-0" type="button"
                              data-toggle="dropdown" :disabled="pqr.stateServices?.code === '0001'">
                        <i class='bx bx-wrench'></i>
                      </button>
                      <div class="dropdown-menu">
                        <h6 class="dropdown-header">¿Qué deseas hacer?</h6>
                        <button type="button" @click="showEdit(pqr); isEdit=true" class="p-3 dropdown-item"
                                data-toggle="modal" >
                          <i class='bx bx-search-alt align-middle'></i> &nbsp;&nbsp; Responder
                        </button>

                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <br/>
              <a-pagination v-model:current="currentPage"
                            v-model:page-size="pageSize"
                            :show-size-changer="false"
                            :total="pqrAll.length"
                            :show-total="total => `${total} reg`"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    Sin registros para mostrar...
  </div>


  <modal-component id-modal="modal-add-pqr" title="Crear nueva pqr">
    <template v-slot:modal-body>
      <form @submit.prevent="addPqr" ref="form-add-pqr">
        <div class="form-row">
          <div class="col-sm-12 ">
            <label class="form-label">Fecha Solicitud</label>
            <input type="string" class="form-control"
                   name="requestDate"
                   :readonly="true"
                   v-model.trim="model.requestDate">
          </div>
          <div class="col-sm-12 ">
            <label class="form-label">Nombre</label>
            <input type="text" class="form-control"
                   name="name"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   :readonly="true"
                   v-model.trim="model.name">
          </div>
          <div class="col-sm-12 ">
            <label class="form-label">Número Matrícula</label>
            <input type="text" class="form-control"
                   name="registerNumber"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   :readonly="isEdit"
                   v-model.trim="model.registerNumber">
          </div>
          <div class="col-sm-12">
            <label class="form-label">Estado</label>
            <select class="form-control form-control-md"
                    name="state"
                    data-required="true"
                    :data-required-msg="validatorMessages.REQUIRED"
                    v-model="model.state">
              <option selected disabled>Seleccione el estado de la PQR...</option>
              <option v-for="(states, key) in stateServicesAll"
                      :key="key"
                      :value="states?.guid">
                {{ states?.name }}
              </option>
            </select>
          </div>
          <div class="col-sm-12 ">
            <label class="form-label">Asunto</label>
            <input type="text" class="form-control"
                   name="subject"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   :readonly="isEdit"
                   v-model.trim="model.subject">
          </div>
          <div class="col-sm-12 ">
            <label class="form-label">Descripción</label>
            <textarea type="text" class="form-control"
                      name="text"
                      data-required="true"
                      :data-required-msg="validatorMessages.REQUIRED"
                      :readonly="isEdit"
                      v-model.trim="model.text"></textarea>
          </div>

          <div class="col-sm-12 ">
            <label class="form-label">Respuesta</label>
            <textarea type="text" class="form-control"
                      name="attendedTxt"
                      data-required="true"
                      :data-required-msg="validatorMessages.REQUIRED"
                      v-model.trim="model.attendedTxt"></textarea>
          </div>

        </div>
      </form>
    </template>
    <template v-slot:modal-footer>
      <button type="submit" class="btn btn-primary" @click="addPqr">Ok</button>
    </template>
  </modal-component>
  <modal-component id-modal="modal-remove-pqr" title="Eliminar PQR">
    <template v-slot:modal-body>
      <h4 class="text-center">¿En verdad desea eliminar este registro?</h4>
    </template>
    <template v-slot:modal-footer>
      <button type="button" class="btn btn-primary" @click="removePqr">Ok</button>
    </template>
  </modal-component>
</template>

<script>
import httpService from "@/services/httpService";
import toastService from "@/services/toastService";
import modalComponent from "@/components/ModalComponent";
import validatorService, { validatorMessages, regexValidator } from "@/services/validatorService";
import modalService from "@/services/modalService";
import {mapGetters} from "vuex";
import utilService from "@/services/utilService";
import dateTimeService from "../services/dateTimeService";

const fieldsToValidate = [
  "name",
]
export default {
  name       : "PqrView",
  components :{modalComponent},
  data:()=>({
    pqrAll           : [],
    userInfo         : {},
    users            : [],
    pqrOriginalAll   : [],
    isEdit           : false,
    pqrDetail        : null,
    stateServicesAll : [],
    currentPage      : 1,
    pageSize         : 10,
    model            : {
      customer:{}
    },
    validatorMessages,
    regexValidator
  }),
  computed: {
    dateTimeService() {
      return dateTimeService
    },
    ...mapGetters([ 'getUserInfo']),
    paginationData(){
      return utilService.filterData(this.pqrAll, this.currentPage, this.pageSize)?.sort(({requestDate: a}, {requestDate: b}) => a < b ? 1 : a > b ? -1 : 0)
    }
  },
  mounted() {
    this.getPqrAll();
    this.getPqrStatesAll();
    this.getAllUsers();
  },
  watch:{
    ...validatorService.init(fieldsToValidate)
  },
  methods:{
    getPqrAll(){
      httpService.get("/Pqr/GetAllAdmin", false)
          .then(res => {
            this.pqrAll         = res?.data?.data;
            this.pqrOriginalAll = res?.data?.data;
          })
          .catch(() => toastService.error())
    },
    getAllUsers(){
      httpService.get("/User/GetAll", false)
          .then(res => {
            this.users         = res?.data?.data;
            console.log(this.users)
          })
          .catch(() => toastService.error())
    },
    getPqrStatesAll(){
      httpService.get("/StateServices/GetAll", false)
          .then(res => this.stateServicesAll = res?.data?.data)
          .catch(() => toastService.error())
    },
    resetForm(){
      this.$refs["form-add-pqr"].reset()
    },
    addPqr() {
      if (!validatorService.validateAll(fieldsToValidate)) return;
      const dataModel = {...this.model}
      dataModel.userAttended = this.getUserInfo.guid;
      const flagUpdate = this.model.guid !== undefined && this.model.guid !== ''
      if(flagUpdate)
        dataModel.responseDate = new Date();
      const options = {
        method   :  flagUpdate ? httpService.types.PUT : httpService.types.POST,
        action : flagUpdate ? "/Pqr/Update" : "/Pqr/Create",
        data   : dataModel
      }
      httpService.execute(options)
          .then(() => {
            modalService.hideModal("modal-add-pqr");
            this.getPqrAll();
            this.model = {};
          })
    },
    showEdit(pqr){
      this.resetForm()
      modalService.showModal('modal-add-pqr');
      this.model = {
        ...pqr,
        name: `${pqr.customer.firstName} ${pqr.customer.secondName} ${pqr.customer.firstSurname} ${pqr.customer.secondSurname}`
      };
      validatorService.reset(this.model)
    },
    removePqr(){
      httpService.delete("Pqr/Delete?guid=" + this.pqrDetail.guid)
          .then(() => {
            modalService.hideModal("modal-remove-pqr");
            this.getPqrAll();
            this.model = {};
          })
    },
    filterSearch(ev, ...args) {
      this.pqrAll = [...this.pqrOriginalAll]
      if (ev.target.value !== "") {
        let data = []
        args.forEach(arg => {
          this.pqrOriginalAll
              .map(x => ({...x.customer, ...x.pqrType, ...x.stateServices, pqr:x.pqrType.name, ...x}))
              .forEach(x => {
            const dataResult = {...x}
            const argument = arg.split(".")
            argument.forEach(item => x = x[item])
            x?.toString().toLowerCase().indexOf(ev.target.value.toString().toLowerCase()) > -1 && data.push(dataResult)
          })
        })
        this.pqrAll = Array.from(new Set(data.map(JSON.stringify))).map(JSON.parse);
      }
    },
    getNameResponseUser(guid){
      let find = this.users.find(el => el.cusGguid == guid);
      if(find != undefined){
        return find.customerDto.firstName + ' '+ find.customerDto.firstSurname
      }
      return '';

    }
  }
}
</script>

<style scoped>
.body-part-text{
  width           : 300px;
    overflow      : hidden;
    text-overflow : ellipsis;
	}
</style>
