import axios from "axios";
import loadingService from "@/services/loadingService";
import toastService from "@/services/toastService";

const URL_API_VIVA_APP = process.env.VUE_APP_API_URL;

axios.interceptors.request.use(config => {
    loadingService.show()
    return config
}, error => {
    loadingService.hide()
    return Promise.reject(error)
})
axios.interceptors.response.use(response =>{
    loadingService.hide()
    return response
}, error => {
    loadingService.hide()
    return Promise.reject(error)
})
const toastHandlers = {
    200: () => toastService.success(),
    201: () => toastService.success(),
    400: (msg = undefined) => toastService.error(msg ?? "Errores de validación han courrido"),
    401: (msg = undefined) => toastService.error(msg),
    403: () => toastService.error(),
    404: () => toastService.error(),
    500: () => toastService.error(),
}

export default {
    execute:({ method, data, action }, useToast = true)=>
        axios({
            method,
            data,
            url :`${URL_API_VIVA_APP}${action}`,
        })
        .then(res => {
            useToast && toastHandlers[res.status]()
            return Promise.resolve(res)
        })
        .catch(err => {
            useToast && toastHandlers[err.response.status](err.response?.data?.message)
            return Promise.reject(err)
        }),
    get:(queryStringOrAction = "", useToast = true)=> axios.get(`${URL_API_VIVA_APP}${queryStringOrAction}`)
        .then(res => {
            useToast && toastHandlers[res.status]()
            return Promise.resolve(res)
        })
        .catch(err => {
            useToast && toastHandlers[err.response.status]()
            return Promise.reject(err)
        }),
    post: (action, data, headers = {}, useToast = true) => axios.post(`${URL_API_VIVA_APP}/${action}`, data, {headers})
        .then(res => {
            useToast && toastHandlers[res.status]()
            return Promise.resolve(res)
        })
        .catch(err => {
            useToast && toastHandlers[err.response.status](err.response.data.message)
            return Promise.reject(err)
        }),
    put:(action, data, headers, useToast = true)=> axios.put(`${URL_API_VIVA_APP}/${action}`, data, { headers })
        .then(res => {
            useToast && toastHandlers[res.status]()
            return Promise.resolve(res)
        })
        .catch(err => {
            useToast && toastHandlers[err.response.status]()
            return Promise.reject(err)
        }),
    delete:(action, headers = {}, useToast = true)=> axios.delete(`${URL_API_VIVA_APP}/${action}`, { headers })
        .then(res => {
            useToast && toastHandlers[res.status]()
            return Promise.resolve(res)
        })
        .catch(err => {
            console.log(err)
            useToast && toastHandlers[err.response.status](err.response?.data?.message)
            return Promise.reject(err)
        }),
    URL_API_VIVA_APP,
    types : {
        POST    : "POST",
        PUT     : "PUT",
        GET     : "GET",
        DELETE  : "DELETE",
    }
}
