<template>
  <div class="row">
    <div class="form-group col-sm-12 col-md-3">
      <div class="input-group">
        <input type="text" class="form-control" placeholder="Buscar..."
               @keyup="ev => filterSearch(ev, 'firstName', 'secondName', 'firstSurname', 'secondSurname', 'mobile', 'registerNumber', 'identification', 'stateDetail')">
        <div class="input-group-append">
          <button class="btn btn-sm btn-primary" type="button"><i class="bx bx-search"></i></button>
        </div>
      </div>
    </div>
    <div class="col-sm-12"></div>
    <div class="col-sm-12 stretch-card grid-margin" style="height: 75vh;overflow-y: auto;"
         v-if="original.length > 0">
      <ul class="icon-data-list w-100">
        <li class="rounded" v-for="(notification, index) in original" :key="index">
          <div class="card">
            <div class="card-body">
              <p class="card-title">Notifications</p>
              <div class="d-flex">
                <div class="preview-list">
                  <div class="preview-item">
                    <div class="preview-thumbnail p-3">
                      <div class="preview-icon bg-warning rounded">
                        <i class="ti-info-alt mx-0"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p class="text-info mb-1">
                    {{ notification?.agreement?.customer?.firstName }}
                    {{ notification?.agreement?.customer?.secondName }}
                    {{ notification?.agreement?.customer?.firstSurname }}
                    {{ notification?.agreement?.customer?.secondSurname }}
                  </p>
                  <p class="mb-0">Documento: {{ notification?.agreement?.customer?.identification }}</p>
                  <p class="mb-0">Matrícula: {{ notification?.agreement?.registerNumber }}</p>
                  <p class="mb-0" v-if="getState(notification?.state).description === 'Solicitado'">Estado:
                    <span class="badge badge-warning">{{ getState(notification?.state).description }}</span>
                  </p>
                  <p class="mb-0" v-if="getState(notification?.state).description === 'Atendido'">Estado:
                    <span class="badge badge-success">{{ getState(notification?.state).description }}</span>
                  </p>
                  <p class="mb-0" v-if="getState(notification?.state).description === 'Cancelado'">Estado:
                    <span class="badge badge-danger">{{ getState(notification?.state).description }}</span>
                  </p>
                  <small>Date: {{ notification?.date }}</small><br/>
                  <small>Teléfono: {{ notification?.agreement?.customer?.mobile }}</small><br/>
                  <small>Dirección: {{ notification?.agreement?.customer?.addressWork }}</small><br/>
                </div>
              </div>
              <div class="text-right" v-if="getState(notification?.state).code === '0002'">
                <div class="dropdown">
                  <button class="btn btn-danger dropdown-toggle btn-sm" type="button" data-toggle="dropdown">
                    <i class="bx bxs-wrench"></i>
                  </button>
                  <div class="dropdown-menu">
                    <h6 class="dropdown-header">Acciones</h6>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#" @click="updateNotification('0001', notification)">Atendido</a>
                    <a class="dropdown-item" href="#" @click="updateNotification('0003', notification)">Cancelado</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div v-else>
      Sin registros para mostrar...
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import httpService from "@/services/httpService";
import axios from "axios";

export default {
  name: "NotificationView",
  props: ['id'],
  data:()=>({
    original: []
  }),
  computed: {
    ...mapGetters([ 'getNotificationsList', 'getAgreements', 'getStateServicesList', 'getUserInfo']),
    stateRequested:function (){
      return this.getStateServicesList.find(x => x.name === "Solicitado");
    },
    notificationList: function (){
      return this.getNotificationsList.filter(x => x.state.toLowerCase() === this.stateRequested.guid)
    },
  },
  mounted() {
    this.original = [...this.getNotificationsList];
  },
  watch:{
    getNotificationsList(){
      this.original = [...this.getNotificationsList];
    }
  },
  methods:{
    ...mapMutations(['SET_ALL_NOTIFICATIONS', 'SET_NOTIFICATIONS_LIST']),
    getState(stateGuid){
      return this.getStateServicesList.find(x => x.guid.toLowerCase() === stateGuid.toLowerCase());
    },
    updateNotification(code, data){
      const { agreement : { registerNumber } } = data
      const model = {
        panicGuid          : data.guid,
        identification     : this.getUserInfo.identification,
        identificationType : this.getUserInfo.identificationType,
        StatePanicGuid     : this.getStateServicesList.find(x => x.code.toLowerCase() === code).guid,
        userNameAttend     : this.getUserInfo.userName,
        registerNumber
      }
      
      httpService.post("Panic/AttendPanic", model)
          .then(()=> axios.get(httpService.URL_API_VIVA_APP + "/Panic/GetAll", {}, false))
          .then((res)=> {
            this.SET_ALL_NOTIFICATIONS(res?.data?.data);
            this.SET_NOTIFICATIONS_LIST();
          })
    },
    filterSearch(ev, ...args) {
      this.original = [...this.getNotificationsList]
      if (ev.target.value !== "") {
        let data = []
        const dataSearching = [
            ...this.getNotificationsList.map(x => (
                {
                  ...x,
                  registerNumber : x.agreement.registerNumber,
                  identification :x.agreement.customer.identification,
                  firstName      :x.agreement.customer.firstName,
                  secondName     :x.agreement.customer.secondName,
                  firstSurname   :x.agreement.customer.firstSurname,
                  secondSurname  :x.agreement.customer.secondSurname,
                  mobile         :x.agreement.customer.phone ?? x.agreement.customer.mobile,
                  stateDetail    : this.getState(x.state)?.description
                }
            ))]
        dataSearching.forEach(x => {
          args.forEach(arg => {
            x[arg]?.toString().toLowerCase().indexOf(ev.target.value.toString().toLowerCase()) > -1 && data.push(x)
          })
        })
        this.original = Array.from(new Set(data.map(JSON.stringify))).map(JSON.parse);
      }
    }
  }
}
</script>
