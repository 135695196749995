<template>
  <h4 class="card-title">¿Qué deseas hacer?</h4>
  <div class="row justify-content-between" style="gap: 15px;height: 60px">
    <div class="col-sm-6">
      <button type="button" class="btn btn-outline-dark btn-sm btn-sm btn-icon-text mb-5"
              data-toggle="modal" data-target="#modal-upload-customer" @click="cleanFile">
        <i class="ti-upload btn-icon-prepend"></i>
        Cargar clientes por archivo
      </button>
      &nbsp;
      <button type="button" class="btn btn-outline-dark btn-sm btn-icon-text mb-5"
              data-toggle="modal" data-target="#modal-add-customer" @click="resetForm">
        <i class='bx bx-user-plus btn-icon-prepend'></i>
        Crear nuevo cliente
      </button>
    </div>
    <div class="form-group col-sm-6 col-md-4">
      <div class="input-group">
        <input type="text" class="form-control" placeholder="Buscar..."
               @keyup="ev => filterSearch(ev, 'firstName', 'firstSurname', 'email', 'mobile', 'identification', 'registerNumber')">
        <div class="input-group-append">
          <button class="btn btn-sm btn-primary" type="button"><i class="bx bx-search"></i></button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="customerAll?.length > 0">
    <div class="row">
      <div class="col-sm-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <p class="card-title mb-0">Clientes</p>
            <div class="table-responsive">
              <table class="table table-striped table-borderless" id="table">
                <thead>
                <tr>
                  <th>Documento</th>
                  <th>Nombres</th>
                  <th>Teléfono</th>
                  <th>e-mail</th>
                  <th>Tipo cliente</th>
                  <th>N° Matrícula</th>
                  <th>Acciones</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(customer, index) in paginationData" :key="index">
                  <td>{{ customer.identification }}</td>
                  <td>{{ getNameToShow(customer) }}</td>
                  <td>{{ customer.mobile }}</td>
                  <td>{{ customer.email }}</td>
                  <td>{{ this.customerTypeAll.find(cus => cus.guid == customer?.customerTypeGuid)?.name  }}</td>
                  <td class="font-weight-bold">{{ customer.agreement?.registerNumber }}</td>
                  <td>
                    <div class="dropdown">
                      <button class="btn btn-sm btn-danger dropdown-toggle m-0" type="button"
                              data-toggle="dropdown">
                        <i class='bx bx-wrench'></i>
                      </button>
                      <div class="dropdown-menu">
                        <h6 class="dropdown-header">¿Qué deseas hacer?</h6>

                        <button type="button" @click="customerDetail = customer" class="p-3 dropdown-item"
                                data-toggle="modal" data-target="#modal-details">
                          <i class='bx bx-search-alt align-middle'></i> &nbsp;&nbsp; Ver cliente
                        </button>
                        <button type="button" @click="showEdit(customer)" class="p-3 dropdown-item">
                          <i class='bx bx-edit-alt'></i> &nbsp;&nbsp; Editar cliente
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <br/>
              <a-pagination v-model:current="currentPage"
                            v-model:page-size="pageSize"
                            :show-size-changer="false"
                            :total="customerAll.length"
                            :show-total="total => `${total} reg`"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    Sin registros para mostrar...
  </div>

  <modal-component id-modal="modal-details" title="Detalle del cliente">
    <template v-slot:modal-body>
      <div class="card">
        <div class="card-body">
          <p class="card-title">{{ customerDetail?.firstName }} {{ customerDetail?.secondName }}
            {{ customerDetail?.firstSurname }} {{ customerDetail?.secondSurname }}</p>
          <ul class="icon-data-list">
            <li>
              <div class="d-flex">
                <img :src="`/images/${customerDetail?.gender === 'M' ? 'hombre':'mujer'}.png`" alt="user">
                <div>
                  <p class="text-info mb-1">{{ customerDetail?.identificationType }} -
                    {{ customerDetail?.identification }}</p>
                  <p class="mb-0">Trade Name: {{ customerDetail?.tradeName }}</p>
                  <i class='bx bx-phone align-middle'></i><small>{{ customerDetail?.mobile }}</small> -
                  <small>{{ customerDetail?.phone }}</small><br/>
                  <i class='bx bx-envelope align-middle'></i><small> {{ customerDetail?.email }}</small><br/>
                  <i class='bx bxs-briefcase align-middle'></i>&nbsp;<i class='bx bx-phone align-middle'></i><small>Teléfono
                  trabajo {{ customerDetail?.phoneWork }}</small><br/>
                  <i class='bx bx-location-plus'></i><small> Dirección trabajo:
                  {{ customerDetail?.addressWork }}</small><br/>
                  <i class='bx bx-street-view'></i><small>
                  {{ customerDetail?.gender === 'M' ? 'Masculino' : 'Femenino' }}</small><br/>
                  <i class='bx bx-buildings'></i><small> Ciudad de nacimiento:
                  {{ getCitiesAndDepartments.cities.find(cit => cit.code == customerDetail?.cityBirth)?.name }}</small><br/>
                  <i class='bx bx-buildings'></i><small> Departamento de nacimiento:
                  {{ getCitiesAndDepartments.departments.find(dep => dep.code == customerDetail?.departamentBirth)?.name }}</small><br/>
                  <i class='bx bxs-baby-carriage'></i><small> Fecha de nacimiento:
                  {{ dateTimeService.formatDate(customerDetail?.dateOfBirth?.toString(), dateTimeService.formats.spanish) }}</small><br/>
                  <i class='bx bx-calendar-event'></i><small> Fecha de creación en sistema:
                  {{ dateTimeService.formatDate(customerDetail?.create?.toString(), dateTimeService.formats.spanish) }}</small><br/>
                  <i class='bx bx-hash'></i><small> Dígito de chequeo: {{ customerDetail?.checkCode }}</small><br/>
                  <i class='bx bxs-info-circle' ></i><small> Descripción: {{ customerDetail?.description }}</small><br/>
                  <i class='bx bx-barcode' ></i><small> Descripción: {{ customerDetail?.internalCode }}</small><br/>
                  <i class='bx bx-buildings'></i><small> Tipo de cliente:
                  {{ customerTypeAll.find(cus => cus.guid == customerDetail?.customerTypeGuid)?.name }}</small><br/>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <template v-slot:modal-footer>
      <button type="button" class="btn btn-primary" data-dismiss="modal">Ok</button>
    </template>
  </modal-component>
  <modal-component id-modal="modal-upload-customer" title="Carga de clientes por archivo">
    <template v-slot:modal-body>
      <form @submit.prevent="sendFileCustomers">
        <div class="mb-3">
          <input class="form-control" type="file" name="file-customers" />
        </div>
      </form>
    </template>
    <template v-slot:modal-footer>
      <button type="submit" class="btn btn-primary" @click="sendFileCustomers">Ok</button>
    </template>
  </modal-component>
  <modal-component id-modal="modal-add-customer" title="Crear nuevo clientes" size="lg">
    <template v-slot:modal-body>
      <form @submit.prevent="addCustomer" id="form-add-customer" ref="form-add-customer">
        <div class="form-row">
          <!-- <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Nombre comercial</label>
            <input type="text" class="form-control"
                   name="tradeName"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model.trim="model.tradeName">
          </div> -->
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Número identificación</label>
            <input type="text"
                   class="form-control"
                   name="identification"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model="model.identification">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Tipo de identificación</label>
            <select class="form-control form-control-md"
                    name="identificationType"
                    data-required="true"
                    :data-required-msg="validatorMessages.REQUIRED"
                    v-model="model.identificationType">
              <option selected disabled>Seleccione Tipo de identificación...</option>
              <option value="CC">Cédula de ciudadanía</option>
              <option value="CE">Cédula de extranjería</option>
              <option value="TI">Tarjeta de identidad</option>
              <option value="PA">Pasaporte</option>
              <option value="RC">Registro Civil</option>
              <option value="OT">Desconocido</option>
              <option value="NUIP">Numero Unico de Identificación Personal</option>
              <option value="NI">NIT</option>
            </select>
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Primer nombre</label>
            <input type="text"
                   class="form-control"
                   name="firstName"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model="model.firstName">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Segundo nombre</label>
            <input type="text"
                   class="form-control"
                   name="secondName"
                   data-required="false"                   
                   v-model="model.secondName">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Primer apellido</label>
            <input type="text"
                   class="form-control"
                   name="firstSurname"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model="model.firstSurname">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Segundo apellido</label>
            <input type="text"
                   class="form-control"
                   name="secondSurname"
                   data-required="false"
                   v-model="model.secondSurname">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Número teléfono personal</label>
            <input type="text"
                   class="form-control"
                   name="phone"
                   data-required="false"
                   :data-required-msg="validatorMessages.REQUIRED"
                   data-number="true"
                   :data-number-msg="validatorMessages.NUMBER"
                   v-model="model.phone">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Número teléfono trabajo</label>
            <input type="text"
                   class="form-control"
                   name="phoneWork"
                   data-required="false"
                   :data-required-msg="validatorMessages.REQUIRED"
                   data-number="true"
                   :data-number-msg="validatorMessages.NUMBER"
                   v-model="model.phoneWork">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Número celular personal</label>
            <input type="text"
                   class="form-control"
                   name="mobile"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   data-number="true"
                   :data-number-msg="validatorMessages.NUMBER"
                   v-model="model.mobile">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Correo</label>
            <input type="text"
                   class="form-control"
                   name="email"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   data-regex="^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$"
                   :data-regex-msg="validatorMessages.EMAIL"
                   v-model.trim="model.email">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Dirección trabajo</label>
            <input type="text"
                   class="form-control"
                   name="addressWork"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model.trim="model.addressWork">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Género</label>
            <select class="form-control form-control-md"
                    name="gender"
                    data-required="true"
                    :data-required-msg="validatorMessages.REQUIRED"
                    v-model.trim="model.gender">
              <option selected disabled>Seleccione género...</option>
              <option value="M">Masculino</option>
              <option value="F">Femenino</option>
            </select>
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Departamento de nacimiento</label>
            <select class="form-control form-control-md"
                    name="departamentBirth"
                    data-required="true"
                    :data-required-msg="validatorMessages.REQUIRED"
                    v-model="model.departamentBirth">
              <option selected >Seleccione departamento...</option>
              <option v-for="(department, key) in getCitiesAndDepartments.departments"
                      :key="key"
                      :value="department">
                {{department?.name}}
              </option>
            </select>
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Ciudad de nacimiento</label>
            <select class="form-control form-control-md"
                    name="cityBirth"
                    data-required="true"
                    :data-required-msg="validatorMessages.REQUIRED"
                    v-model="model.cityBirth">
              <option selected disabled>Seleccione ciudad...</option>
              <option v-for="(city, key) in getCitiesAndDepartments.cities.filter(x => x.codeStat === model?.departamentBirth?.code)"
                      :value="city.code"
                      :key="key">
                {{city?.name}}
              </option>
            </select>
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Fecha nacimiento</label>
            <input type="date"
                   class="form-control"
                   name="dateOfBirth"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model.trim="model.dateOfBirth">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Fecha expedición de documento</label>
            <input type="date" class="form-control"
                   name="dateDocumentIssuance"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model.trim="model.dateDocumentIssuance">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Número de matrícula</label>
            <input type="text" class="form-control"
                   name="registerNumber"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model.trim="model.registerNumber">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Tipo de cliente</label>
            <select class="form-control form-control-md"
                    name="customerTypeGuid"
                    data-required="true"
                    :data-required-msg="validatorMessages.REQUIRED"
                    v-model="model.customerTypeGuid">
              <option selected disabled>Seleccione el tipo de cliente...</option>
              <option v-for="(custype, key) in customerTypeAll.filter(x => x.code != '0003' )"
                      :value="custype.guid"
                      :key="key">
                {{custype?.name}}
              </option>
            </select>
          </div>
        </div>
      </form>
    </template>
    <template v-slot:modal-footer>
      <button type="submit" class="btn btn-primary" @click="addCustomer">Ok</button>
    </template>
  </modal-component>
</template>

<script>
import httpService from "@/services/httpService";
import ModalComponent from "@/components/ModalComponent";
import dateTimeService from "@/services/dateTimeService";
import toastService from "@/services/toastService";
import modalService from "@/services/modalService";
import {mapGetters} from "vuex"
import validatorService, {validatorMessages} from "@/services/validatorService";
import utilService from "@/services/utilService";

const fieldsToValidate = [
  "identification",
  "identificationType",
  "firstName",
  "firstSurname",
  "phone",
  "phoneWork",
  "mobile",
  "email",
  "addressWork",
  "gender",
  "departamentBirth",
  "cityBirth",
  "dateOfBirth",
  "dateDocumentIssuance",
  "registerNumber",
  "customerTypeGuid"
]

export default {
  name: "CustomerView",
  components: { ModalComponent },
  data:()=>({
    customerAll         :[],
    customerOriginalAll :[],
    customerTypeAll     :[],
    customerDetail      : null,
    fileModel           : null,
    model               :{ },
    currentPage         : 1,
    pageSize            : 10,
    validatorMessages,
    dateTimeService,
  }),
  computed: {
    ...mapGetters(['getCitiesAndDepartments']),
    paginationData() {
      return utilService.filterData(this.customerAll, this.currentPage, this.pageSize)
    }
  },
  mounted() {
    this.getCustomerAll();
    this.getCustomerTypeAll();
  },
  watch: {
    ...validatorService.init(fieldsToValidate),
  },
  methods:{
    getCustomerAll(){
      httpService.get("/Customer/GetAll", false)
          .then(res => {
            console.log({customerAll:res})
            this.customerAll         = res?.data?.data;
            this.customerOriginalAll = res?.data?.data;
          })
          .catch(() => toastService.error())
    },
    getCustomerTypeAll(){
      httpService.get("/CustomerType/GetAll", false)
          .then(res => {
            console.log({customerType:res})
            this.customerTypeAll         = res?.data?.data;
          })
          .catch(() => toastService.error())
    },
    sendFileCustomers(){
      const formData = new FormData(document.querySelector("form"))
      formData.append("file", document.querySelector("input[name=file-customers]").files[0])
      const ACTION = "Customer/LoadFile"
      httpService.post(ACTION, formData, {"Content-Type": "multipart/form-data"}, false)
          .then(() => {
            this.getCustomerAll();
            toastService.success();
          })
          .catch(({response}) => {
            if (response.data.code === 400)
              toastService.error(response.data.message);
            else
              toastService.error();
          })
          .finally(()=> modalService.hideModal("modal-upload-customer"));
    },
    resetForm(){
      this.$refs["form-add-customer"].reset()
    },
    addCustomer() {
      if (!validatorService.validateAll(fieldsToValidate)) return;
      console.log({model:this.model})
      const customer = { ...this.model }
      console.log({customer:customer})

      customer.departamentBirth = customer.departamentBirth.code
      const flagUpdate = this.model.guid !== undefined && this.model.guid !== ''
      const options = {
        method   :  flagUpdate ? httpService.types.PUT : httpService.types.POST,
        action : flagUpdate ? "/Customer/Update" : "/Customer/Create",
        data   : customer
      }
      httpService.execute(options)
          .then(() => {
            modalService.hideModal("modal-add-customer");
            this.getCustomerAll();
            this.model = {}
          }).catch(({response}) => {
            if (response.data.code === 400)
              toastService.error(response.data.message);
            else
              toastService.error();
          })
    },
    showEdit(customer){
      modalService.showModal('modal-add-customer');
      this.model = {
        ...customer,
        registerNumber       : customer.agreement?.registerNumber,
        departamentBirth     : this.getCitiesAndDepartments.departments.find(x => x.code === customer.departamentBirth),
        dateOfBirth          : customer.dateOfBirth.split('T')[0],
        dateDocumentIssuance : customer.dateDocumentIssuance.split('T')[0],
      };
      validatorService.reset(this.model)
    },
    cleanFile(){
      document.querySelector('[name=file-customers]').value = ''
    },
    filterSearch(ev, ...args) {
      this.customerAll = [...this.customerOriginalAll];
      if (ev.target.value !== "") {
        let data = []
        args.forEach(arg => {
          this.customerOriginalAll.map(x => ({...x, registerNumber:x.agreement?.registerNumber})).forEach(x => {
            const dataResult = {...x}
            const argument = arg.split(".")
            argument.forEach(item => x = x[item])
            x?.toString().toLowerCase().indexOf(ev.target.value.toString().toLowerCase()) > -1 && data.push(dataResult)
          })
        })
        this.customerAll = Array.from(new Set(data.map(JSON.stringify))).map(JSON.parse);
      }
    },
    getNameToShow(customer){
      if(customer.firstName != '' || customer.firstSurname != ''){
        return customer.firstName +' '+ customer?.secondName +' '+ customer?.firstSurname +' '+ customer?.secondSurname
      }else if(customer.firstName == '' && customer.firstSurname == ''){
        return customer?.tradeName
      }
    }
  }
}
</script>
