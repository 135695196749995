<template>
  <h4 class="card-title">¿Qué deseas hacer?</h4>
  <div class="row justify-content-between" style="gap: 15px;height: 60px">
    <div class="col-sm-6 col-md-4">
      <button type="button" class="btn btn-outline-dark btn-sm btn-icon-text mb-5"
              data-toggle="modal" data-target="#modal-add-provider" @click="resetForm">
        <i class='bx bx-user-plus btn-icon-prepend'></i>
        Crear nuevo proveedor
      </button>
    </div>
    <div class="form-group col-sm-6 col-md-4">
      <div class="input-group">
        <input type="text" class="form-control" placeholder="Buscar..."
               @keyup="ev => filterSearch(ev, 'name', 'identification', 'phone', 'phone2', 'email')">
        <div class="input-group-append">
          <button class="btn btn-sm btn-primary" type="button"><i class="bx bx-search"></i></button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="providerAll?.length > 0">
    <div class="row">
      <div class="col-sm-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <p class="card-title mb-0">Proveedores</p>
            <div class="table-responsive">
              <table class="table table-striped table-borderless">
                <thead>
                <tr>
                  <th>Tipo Identificación</th>
                  <th>Identificación</th>
                  <th>Nombres</th>
                  <th>e-mail</th>
                  <th>Teléfono</th>
                  <th>Acciones</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(provider, index) in paginationData" :key="index">
                  <td>{{ provider.identificationType }}</td>
                  <td>{{ provider.identification }}</td>
                  <td>{{ provider.name }}</td>
                  <td>{{ provider.email }}</td>
                  <td>{{ provider.phone }}</td>
                  <td>
                    <div class="dropdown">
                      <button class="btn btn-sm btn-danger dropdown-toggle m-0" type="button"
                              data-toggle="dropdown">
                        <i class='bx bx-wrench'></i>
                      </button>
                      <div class="dropdown-menu">
                        <h6 class="dropdown-header">¿Qué deseas hacer?</h6>
                        <button type="button" @click="providerDetail = provider" class="p-3 dropdown-item"
                                data-toggle="modal" data-target="#modal-details">
                          <i class='bx bx-search-alt align-middle'></i> &nbsp;&nbsp; Ver proveedor
                        </button>
                        <button type="button" @click="showEdit(provider)" class="p-3 dropdown-item"
                                data-toggle="modal" >
                          <i class='bx bx-search-alt align-middle'></i> &nbsp;&nbsp; Editar proveedor
                        </button>
                        <button type="button" @click="showDelete(provider.guid)" class="p-3 dropdown-item"
                                data-toggle="modal" >
                          <i class='bx bx-trash align-middle'></i> &nbsp;&nbsp; Eliminar
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <a-pagination v-model:current="currentPage"
                            v-model:page-size="pageSize"
                            :show-size-changer="false"
                            :total="providerAll.length"
                            :show-total="total => `${total} reg`"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    Sin registros para mostrar...
  </div>

  <modal-component id-modal="modal-details" title="Detalle del proveedor">
    <template v-slot:modal-body>
      <div class="card">
        <div class="card-body">
          <p class="card-title">{{ providerDetail?.name }}</p>
          <ul class="icon-data-list">
            <li>
              <div class="d-flex">
                <div>
                  <p class="text-info mb-1">{{ providerDetail?.identificationType }}</p>
                  <p class="text-info mb-1">{{ providerDetail?.descripcion }}</p>
                  <i class='bx bx-phone align-middle'></i><small> {{ providerDetail?.phone }} - {{ providerDetail?.phone2 }}</small> <br/>
                  <i class='bx bx-id-card' ></i><small> {{ providerDetail?.identification }}</small><br/>
                  <i class='bx bx-envelope align-middle'></i><small> {{ providerDetail?.email }}</small><br/>
                  <i class='bx bx-street-view' ></i><small> {{ providerDetail?.address }}</small><br/>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <template v-slot:modal-footer>
      <button type="button" class="btn btn-primary" data-dismiss="modal">Ok</button>
    </template>
  </modal-component>
  <modal-component id-modal="modal-delete" title="Eliminar el proveedor">
    <template v-slot:modal-body>
      <div class="card">
        <div class="card-body">
          <p class="card-title">¿En verdad desea eliminar el registro?</p>
        </div>
      </div>
    </template>
    <template v-slot:modal-footer>
      <button type="button" class="btn btn-primary" data-dismiss="modal" @click="remove">Ok</button>
    </template>
  </modal-component>
  <modal-component id-modal="modal-add-provider" title="Crear nuevo proveedor" size="lg">
    <template v-slot:modal-body>
      <form @submit.prevent="addProvider" id="form-add-provider" ref="form-add-provider">
        <div class="form-row">
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Nombre comercial</label>
            <input type="text" class="form-control"
                   name="name"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model.trim="model.name">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Tipo de identificación</label>
            <select class="form-control form-control-md"
                    name="identificationType"
                    data-required="true"
                    :data-required-msg="validatorMessages.REQUIRED"
                    v-model.trim="model.identificationType">
              <option selected disabled>Seleccione Tipo de identificación...</option>
              <option value="CC">Cédula de ciudadanía</option>
              <option value="CE">Cédula de extranjería</option>
              <option value="TI">Tarjeta de identidad</option>
              <option value="PA">Pasaporte</option>
              <option value="RC">Registro Civil</option>
              <option value="OT">Desconocido</option>
              <option value="NUIP">Numero Unico de Identificación Personal</option>
              <option value="NI">NIT</option>
            </select>
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Identificación</label>
            <input type="text" class="form-control"
                   name="identification"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model.trim="model.identification">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Descripción</label>
            <input type="text" class="form-control"
                   name="descripcion"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model.number.trim="model.descripcion">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Teléfono</label>
            <input type="text" class="form-control"
                   name="phone"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   data-number="true"
                   :data-number-msg="validatorMessages.NUMBER"
                   v-model.trim="model.phone">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Teléfono #2</label>
            <input type="text" class="form-control"
                   name="phone2"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   data-number="true"
                   :data-number-msg="validatorMessages.NUMBER"
                   v-model.trim="model.phone2">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Dirección</label>
            <input type="text" class="form-control"
                   name="address"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model.number.trim="model.address">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Correo</label>
            <input type="text"
                   class="form-control"
                   name="email"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   data-regex="^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$"
                   :data-regex-msg="validatorMessages.EMAIL"
                   v-model.trim="model.email">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Departamento</label>
            <select class="form-control form-control-md"
                    name="departament"
                    data-required="true"
                    :data-required-msg="validatorMessages.REQUIRED"
                    v-model="model.departament">
              <option selected disabled>Seleccione departamento...</option>
              <option v-for="(department, key) in getCitiesAndDepartments.departments"
                      :key="key"
                      :value="department">
                {{department?.name}}
              </option>
            </select>
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Ciudad</label>
            <select class="form-control form-control-md"
                    name="city"
                    data-required="true"
                    :data-required-msg="validatorMessages.REQUIRED"
                    v-model="model.city">
              <option selected disabled>Seleccione ciudad...</option>
              <option v-for="(city, key) in getCitiesAndDepartments.cities.filter(x => x.codeStat === model?.departament?.code)"
                      :value="city.codeStat"
                      :key="key">
                {{city?.name}}
              </option>
            </select>
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Sitio web</label>
            <input type="text" class="form-control"
                   name="website"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model.number.trim="model.website">
          </div>
        </div>
      </form>
    </template>
    <template v-slot:modal-footer>
      <button type="submit" class="btn btn-primary" @click="addProvider">Ok</button>
    </template>
  </modal-component>
</template>

<script>
import httpService from "@/services/httpService";
import toastService from "@/services/toastService";
import modalComponent from "@/components/ModalComponent";
import validatorService, { validatorMessages } from "@/services/validatorService";
import modalService from "@/services/modalService";
import {mapGetters} from "vuex";
import utilService from "@/services/utilService";

const fieldsToValidate = [
  "name",
  "identificationType",
  "identification",
  "descripcion",
  "phone",
  "phone2",
  "address",
  "email",
  "departament",
  "city",
  "website",
]
export default {
  name       : "ProviderView",
  components :{modalComponent},
  data:()=>({
    providerAll         :[],
    providerOriginalAll :[],
    providerDetail      : null,
    model               :{ },
    currentPage         : 1,
    pageSize            : 10,
    guidDelete : null,
    validatorMessages
  }),
  mounted() {
    this.getProviderAll()
  },
  watch:{
    ...validatorService.init(fieldsToValidate)
  },
  computed:{ ...mapGetters(['getCitiesAndDepartments']),
    paginationData(){
      return utilService.filterData(this.providerAll, this.currentPage, this.pageSize)
    } },
  methods:{
    getProviderAll(){
      httpService.get("/Provider/GetAll", false)
          .then(res => {
            this.providerAll = res?.data?.data;
            this.providerOriginalAll = res?.data?.data;
          })
          .catch(() => toastService.error())
    },
    resetForm(){
      this.$refs["form-add-provider"].reset()
      this.model = {};
    },
    addProvider() {
      if (!validatorService.validateAll(fieldsToValidate)) return;
      const dataModel            = {...this.model}
      dataModel.departament      = dataModel.departament.code
      const flagUpdate = this.model.guid !== undefined && this.model.guid !== ''
      const options = {
        method   :  flagUpdate ? httpService.types.PUT : httpService.types.POST,
        action   : flagUpdate ? "/Provider/Update" : "/Provider/Create",
        data     : dataModel
      }
      httpService.execute(options)
          .then(() => {
            modalService.hideModal("modal-add-provider");
            this.getProviderAll();
            this.model = {}
          })
    },
    showEdit(provider){
      this.resetForm()
      this.model = {
        ...provider,
        departament: this.getCitiesAndDepartments.departments.find(x => x.code === provider.departament),
      };
      modalService.showModal('modal-add-provider');
      validatorService.reset(this.model)
    },
    showDelete(guid){
      this.guidDelete = guid;
      modalService.showModal('modal-delete');
    },
    remove() {
      httpService.delete("Provider/DeleteProvider?guid=" + this.guidDelete)
          .then(() => {
            modalService.hideModal("modal-delete");
            this.getProviderAll();
            this.guidDelete = null;
          })
    },
    filterSearch(ev, ...args) {
      this.providerAll = [...this.providerOriginalAll]
      if (ev.target.value !== "") {
        let data = []
        args.forEach(arg => {
          this.providerOriginalAll.forEach(x => {
            const dataResult = {...x}
            const argument = arg.split(".")
            argument.forEach(item => x = x[item])
            x?.toString().toLowerCase().indexOf(ev.target.value.toString().toLowerCase()) > -1 && data.push(dataResult)
          })
        })
        this.providerAll = [...data]
      }
    }
  },
}
</script>

<style scoped>

</style>
