<template>
  <h4 class="card-title">¿Qué deseas hacer?</h4>
  <div class="row justify-content-between" style="gap: 15px;height: 60px">
    <div class="col-sm-6">
      <button type="button" class="btn btn-outline-dark btn-sm btn-icon-text mb-5"
              data-toggle="modal" data-target="#modal-add-documentPoll">
        <i class='bx bx-user-plus btn-icon-prepend'></i>
        Crear nuevo documento
      </button>
    </div>
    <div class="form-group col-sm-6 col-md-4">
      <div class="input-group">
        <input type="text" class="form-control" placeholder="Buscar..."
               @keyup="ev => filterSearch(ev, 'description')">
        <div class="input-group-append">
          <button class="btn btn-sm btn-primary" type="button"><i class="bx bx-search"></i></button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="documentPollAll?.length > 0">
    <div class="row">
      <div class="col-sm-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <p class="card-title mb-0">Documentos Formularios Encuestas</p>
            <div class="table-responsive">
              <table class="table table-striped table-borderless">
                <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Url del documento</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(documentPoll, index) in paginationData" :key="index">
                  <td >{{ documentPoll.description }}</td>
                  <td >{{ documentPoll.url }}</td>
                  <td>
                    <div class="dropdown">
                      <button class="btn btn-sm btn-danger dropdown-toggle m-0" type="button"
                              data-toggle="dropdown">
                        <i class='bx bx-wrench'></i>
                      </button>
                      <div class="dropdown-menu">
                        <h6 class="dropdown-header">¿Qué deseas hacer?</h6>
                        <button type="button" @click="documentPollDetail = documentPoll" class="p-3 dropdown-item"
                                data-toggle="modal" data-target="#modal-details">
                          <i class='bx bx-search-alt align-middle'></i> &nbsp;&nbsp; Ver formulario
                        </button>
                        <button type="button" @click="showEdit(documentPoll)" class="p-3 dropdown-item"
                                data-toggle="modal" >
                          <i class='bx bx-search-alt align-middle'></i> &nbsp;&nbsp; Editar formulario
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <br/>
              <a-pagination v-model:current="currentPage"
                            v-model:page-size="pageSize"
                            :show-size-changer="false"
                            :total="documentPollAll.length"
                            :show-total="total => `${total} reg`"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    Sin registros para mostrar...
  </div>

  <modal-component id-modal="modal-details" title="Detalle del formulario">
    <template v-slot:modal-body>
      <div class="card">
        <div class="card-body">
          <p class="card-title">{{ documentPollDetail?.description }}</p>
          <ul class="icon-data-list">
            <li>
              <div class="d-flex">
                <div>
                  <p class="text-info mb-1">{{ documentPollDetail?.url }}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <template v-slot:modal-footer>
      <button type="button" class="btn btn-primary" data-dismiss="modal">Ok</button>
    </template>
  </modal-component>
  <modal-component id-modal="modal-add-documentPoll" title="Crear nuevo formulario">
    <template v-slot:modal-body>
      <form @submit.prevent="addDocumentPoll" ref="form-add-documentPoll">
        <div class="form-row">
          <div class="col-sm-12 ">
            <label class="form-label">Nombre formulario</label>
            <input type="text" class="form-control"
                   name="description"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model.trim="model.description">
          </div>

          <div class="col-sm-12 ">
            <label class="form-label">Url formulario</label>
            <input type="text" class="form-control"
                   name="url"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model.trim="model.url">
          </div>

        </div>
      </form>
    </template>
    <template v-slot:modal-footer>
      <button type="submit" class="btn btn-primary" @click="addDocumentPoll">Ok</button>
    </template>
  </modal-component>
</template>

<script>
import httpService from "@/services/httpService";
import toastService from "@/services/toastService";
import modalComponent from "@/components/ModalComponent";
import validatorService, { validatorMessages } from "@/services/validatorService";
import modalService from "@/services/modalService";
import utilService from "@/services/utilService";

const fieldsToValidate = [
  "description",
  "url"
]
export default {
  name       : "DocumentPollView",
  components :{modalComponent},
  data:()=>({
    documentPollAll         :[],
    documentPollOriginalAll :[],
    documentPollDetail      : null,
    model                   :{ },
    currentPage            : 1,
    pageSize               : 10,
    validatorMessages
  }),
  mounted() {
    this.getDocumentPollAll()
  },
  computed:{
    paginationData(){
      return utilService.filterData(this.documentPollAll, this.currentPage, this.pageSize)
    }
  },
  watch:{
    ...validatorService.init(fieldsToValidate)
  },
  methods:{
    getDocumentPollAll(){
      httpService.get("/DocumentPoll/GetAll", false)
          .then(res => {
            this.model                   = {};
            this.documentPollAll         = res?.data?.data;
            this.documentPollOriginalAll = res?.data?.data;
          })
          .catch(() => toastService.error())
    },
    resetForm(){
      this.$refs["form-add-documentPoll"].reset()
    },
    addDocumentPoll() {
      if (!validatorService.validateAll(fieldsToValidate)) return;
      const dataModel = {...this.model}
      this.model.guid != undefined && this.model.guid != '' ?
      httpService.put("DocumentPoll/Update", {...dataModel})
          .then(() => {
            modalService.hideModal("modal-add-documentPoll");
            this.getDocumentPollAll();
          })
          :
      httpService.post("DocumentPoll/Create", {...dataModel})
          .then(() => {
            modalService.hideModal("modal-add-documentPoll");
            this.getDocumentPollAll();
          })
    },
    showEdit(documentPoll){
      console.log({documentPoll})
      this.resetForm()
      this.model = documentPoll;
      validatorService.reset(this.model);
      modalService.showModal('modal-add-documentPoll');
    },
    filterSearch(ev, ...args) {
      this.documentPollAll = [...this.documentPollOriginalAll]
      if (ev.target.value !== "") {
        let data = []
        args.forEach(arg => {
          this.documentPollOriginalAll.forEach(x => {
            const dataResult = {...x}
            const argument = arg.split(".")
            argument.forEach(item => x = x[item])
            x?.toString().toLowerCase().indexOf(ev.target.value.toString().toLowerCase()) > -1 && data.push(dataResult)
          })
        })
        this.documentPollAll = [...data]
      }
    }
  }
}
</script>

<style scoped>

</style>
