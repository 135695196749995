<template>
  <div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
      <div class="content-wrapper d-flex align-items-center auth px-0">
        <div class="row w-100 mx-0">
          <div class="col-lg-4 mx-auto">
            <div class="auth-form-light text-left py-5 px-4 px-sm-5">
              <div class="brand-logo">
                <img src="../assets/images/viva-app-logo-simple.png" alt="logo">
              </div>
              <h6 class="font-weight-light">Ingrese sus datos para continuar.</h6>

              <a-tabs v-model:activeKey="isLogin">
                <a-tab-pane key="1" tab="Login" force-render>
                  <form class="pt-3" ref="form-login" @submit.prevent="restorePassword">
                    <div class="form-group">
                      <input type="text" class="form-control"
                             name="userName"
                             placeholder="Nombre de usuario (e-mail)."
                             data-required="true"
                             :data-required-msg="validatorMessages.REQUIRED"
                             v-model.trim="model.userName">
                    </div>
                    <div class="form-group">
                      <input type="password" class="form-control"
                             name="password"
                             placeholder="Password"
                             data-required="true"
                             :data-required-msg="validatorMessages.REQUIRED"
                             v-model.trim="model.password">
                    </div>
                    <div class="mt-3">
                      <button type="button" class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                              @click="login">INGRESAR
                      </button>
                    </div>
                  </form>
                </a-tab-pane>
                <a-tab-pane key="2" tab="Registrar usuario">
                  <form class="pt-3" ref="form-login" @submit.prevent="registerUser">
                    <div class="form-group">
                      <input type="text" class="form-control"
                             name="document"
                             placeholder="Documento de identidad..."
                             data-required="true"
                             maxlength="20"
                             :data-required-msg="validatorMessages.REQUIRED"
                             v-model.trim="modelRegister.document">
                    </div>
                    <div class="form-group">
                      <input type="text" class="form-control"
                             name="userNameRegister"
                             placeholder="Nombre de usuario (e-mail)."
                             data-required="true"
                             maxlength="60"
                             :data-required-msg="validatorMessages.REQUIRED"
                             v-model.trim="modelRegister.userNameRegister">
                    </div>
                    <div class="form-group">
                      <input type="password" class="form-control"
                             name="registerPassword"
                             placeholder="Password"
                             data-required="true"
                             :data-required-msg="validatorMessages.REQUIRED"
                             v-model.trim="modelRegister.registerPassword">
                    </div>
                    <div class="mt-3">
                      <button type="button" class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                              @click="registerUser">Registrar
                      </button>
                    </div>
                  </form>
                </a-tab-pane>
                <a-tab-pane key="3" tab="Recuperar contraseña">
                  <form class="pt-3" ref="form-login" @submit.prevent="restorePassword">
                    <div class="form-group">
                      <input type="text" class="form-control"
                             name="userNameRestore"
                             placeholder="Nombre de usuario"
                             data-required="true"
                             :data-required-msg="validatorMessages.REQUIRED"
                             v-model.trim="modelRestore.userNameRestore">
                    </div>
                    <div class="mt-3">
                      <button type="button" class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                              @click="restorePassword">Enviar correo
                      </button>
                    </div>
                  </form>
                </a-tab-pane>
              </a-tabs>
            </div>
          </div>
        </div>
      </div>
      <!-- content-wrapper ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
</template>

<script>
import validatorService, {validatorMessages} from "@/services/validatorService";
import httpService from "@/services/httpService";
import {mapMutations} from "vuex";
import toastService from "@/services/toastService";

const fieldsToValidate = [
  "userName",
  "password",
]

export default {
  name: "LoginView",
  data: () => ({
    isLogin: "1",
    model: {},
    modelRestore: {},
    modelRegister: {},
  }),
  watch: {
    ...validatorService.init(fieldsToValidate),
  },
  computed: {
    validatorMessages() {
      return validatorMessages
    }
  },
  methods: {
    ...mapMutations(['SET_AUTH', 'SET_CURRENT_PROFILE']),
    login() {
      if (!validatorService.validateAll(fieldsToValidate)) return;
      httpService.post("User/Login", {...this.model, password: btoa(this.model.password)})
          .then(res => {
            if (res.data.code === 200) {
              this.SET_AUTH({userName: this.model.userName, ...res.data.data});
              return httpService.get("/User/GetCurrentUser/" + this.model.userName, true)
            } else {
              toastService.error(res.data.message)
            }
          })
          .then(({data: {data: user}}) => {
            this.SET_CURRENT_PROFILE(user.profile)
            this.$router.push({name: 'home'});
          });
    },
    restorePassword(){
      if (!validatorService.validateAll(["userNameRestore"])) return;
      httpService.post(`User/SendMailChangePassword`, {email:this.modelRestore.userNameRestore})
          .then(() => {
            toastService.success("Se ha enviado un correo con el enlace para restaurar su contraseña.");
            setTimeout(()=> window.location.href = "/", 2000);
          });
    },
    registerUser() {
      if (!validatorService.validateAll(["userNameRegister", "document", "registerPassword"])) return;
      httpService.post(`User/SetPasswordNewUser`, {
        email       : this.modelRegister.userNameRegister,
        document    : this.modelRegister.document,
        newPassword : btoa(this.modelRegister.registerPassword),
      })
      .then(() => {
        toastService.success("Solicitud realizada con éxito.");
        setTimeout(() => window.location.href = "/", 2000);
      });
    }
  }
}
</script>
<style scoped>
.auth .brand-logo {
  margin-bottom: 0 !important;
}
</style>
