<template>
  <h4 class="card-title">¿Qué deseas hacer?</h4>
  <div class="row justify-content-between" style="gap: 15px;height: 60px">
    <div class="col-sm-6">
      <button type="button" class="btn btn-outline-dark btn-sm btn-icon-text mb-5"
              data-toggle="modal" data-target="#modal-add-service-provider" @click="model = {}">
        <i class='bx bx-user-plus btn-icon-prepend'></i>
        Asociar Servicio - Proveedor
      </button>
    </div>
    <div class="form-group col-sm-6 col-md-4">
      <div class="input-group">
        <input type="text" class="form-control" placeholder="Buscar..."
               @keyup="ev => filterSearch(ev, 'serviceName', 'providerName')">
        <div class="input-group-append">
          <button class="btn btn-sm btn-primary" type="button"><i class="bx bx-search"></i></button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="servicesProvidersAll?.length > 0">
    <div class="row">
      <div class="col-sm-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <p class="card-title mb-0">Serviocios - Proveedor</p>
            <div class="table-responsive">
              <table class="table table-striped table-borderless">
                <thead>
                <tr>
                  <th>Servicio</th>
                  <th>Provdeedor</th>
                  <th>Acciones</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(serviceProvider, index) in servicesProvidersAll" :key="index">
                  <td>{{serviceProvider.serviceName}}</td>
                  <td>{{serviceProvider.providerName}}</td>
                  <td>
                    <div class="dropdown">
                      <button class="btn btn-sm btn-danger dropdown-toggle m-0" type="button"
                              data-toggle="dropdown">
                        <i class='bx bx-wrench'></i>
                      </button>
                      <div class="dropdown-menu">
                        <h6 class="dropdown-header">¿Qué deseas hacer?</h6>
                        <button type="button" @click="showEdit(serviceProvider)" class="p-3 dropdown-item"
                                data-toggle="modal" data-target="#modal-add-service-provider">
                          <i class='bx bx-edit-alt align-middle'></i> &nbsp;&nbsp; Editar
                        </button>
                        <button type="button" class="p-3 dropdown-item"
                                @click="guidDelete = serviceProvider.SvpGguid"
                                data-toggle="modal" data-target="#modal-remove">
                          <i class='bx bx-trash align-middle'></i> &nbsp;&nbsp; Eliminar
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <br/>
              <a-pagination v-model:current="currentPage"
                            v-model:page-size="pageSize"
                            :show-size-changer="false"
                            :total="servicesAll.length"
                            :show-total="total => `${total} reg`"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    Sin registros para mostrar...
  </div>

  <modal-component id-modal="modal-add-service-provider" title="Asociar Servicio - Proveedor">
    <template v-slot:modal-body>
      <form @submit.prevent="add" ref="form-add">
        <div class="form-row">
          <div class="col-sm-12">
            <label class="form-label">Servicio</label>
            <select class="form-control form-control-md"
                    name="service"
                    v-model="model.svcGguid"
                    data-required="true"
                    data-required-msg="Campo requerido.">
              <option disabled="" selected>Seleccione servicio...</option>
              <option v-for="(service, index) in servicesAll.filter(x => x.serviceEnable === true)" :key="index" :value="service.guid">{{service.serviceName}}</option>
            </select>
          </div>
          <div class="col-sm-12">
            <label class="form-label">Proveedor</label>
            <select class="form-control form-control-md"
                    name="provider"
                    v-model="model.proGguid"
                    data-required="true"
                    data-required-msg="Campo requerido.">
              <option disabled="" selected>Seleccione proveedor...</option>
              <option v-for="(provider, index) in providersAll" :key="index" :value="provider.guid">{{provider.name}}</option>
            </select>
          </div>
        </div>
      </form>
    </template>
    <template v-slot:modal-footer>
      <button type="submit" class="btn btn-primary" @click="add">Ok</button>
    </template>
  </modal-component>
  <modal-component id-modal="modal-remove" title="Eliminar Servicio - Proveedor">
    <template v-slot:modal-body>
      <h4 class="text-center">¿En verdad desea eliminar este registro?</h4>
    </template>
    <template v-slot:modal-footer>
      <button type="button" class="btn btn-primary" @click="remove">Ok</button>
    </template>
  </modal-component>
</template>

<script>
import modalComponent from "@/components/ModalComponent.vue";
import validatorService, {regexValidator, validatorMessages} from "@/services/validatorService";
import httpService from "@/services/httpService";
import axios from "axios";
import toastService from "@/services/toastService";
import modalService from "@/services/modalService";
import utilService from "@/services/utilService";
const fieldsToValidate = [
  'service',
  'provider',
];
export default {
  name: "ServiceProviderView",
  components: {modalComponent},
  data:()=>({
    servicesAll                  :[],
    providersAll                 :[],
    servicesProvidersAll         :[],
    servicesProvidersOriginalAll :[],
    guidDelete: null,
    currentPage            : 1,
    pageSize               : 10,
    model : {},
    validatorMessages,
    regexValidator,
  }),
  mounted() {
    this.getAll()
  },
  computed:{
    paginationData(){
      return utilService.filterData(this.servicesAll, this.currentPage, this.pageSize)
    }
  },
  watch:{
    ...validatorService.init(fieldsToValidate)
  },
  methods:{
    getAll(){
      const providers = httpService.get("/Provider/GetAll", false);
      const services = httpService.get("/Service/GetAll", false);
      const servicesProviders = httpService.get("/ServicesProvider/GetAllAdmin", false);
      axios.all([providers, services, servicesProviders])
          .then(res => {
            this.providersAll                 = res[0]?.data?.data;
            this.servicesAll                  = res[1]?.data?.data;
            this.servicesProvidersAll         = res[2]?.data?.data;
            this.servicesProvidersOriginalAll = res[2]?.data?.data;
          })
          .catch(()=> toastService.error())
    },
    add(){
      if (!validatorService.validateAll(fieldsToValidate)) return;
      const dataModel = {...this.model};
      const flagUpdate = this.model?.svpGguid !== undefined;
      const options = {
        method   :  flagUpdate ? httpService.types.PUT : httpService.types.POST,
        action : flagUpdate ? "/ServicesProvider/Update" : "/ServicesProvider/Create",
        data   : dataModel
      }
      httpService.execute(options)
          .then(() => {
            modalService.hideModal("modal-add-service-provider");
            this.getAll();
            this.model = {};
          })
          .catch(({response}) => toastService.error(response?.message))
    },
    showEdit(serviceProvider){
      this.model = {
        svpGguid : serviceProvider.SvpGguid,
        svcGguid: serviceProvider.SvcGguid,
        proGguid: serviceProvider.ProGguid,
      }
    },
    filterSearch(ev, ...args) {
      this.servicesProvidersAll = [...this.servicesProvidersOriginalAll]
      if (ev.target.value !== "") {
        let data = []
        args.forEach(arg => {
          this.servicesProvidersOriginalAll.forEach(x => {
            const dataResult = {...x}
            const argument = arg.split(".")
            argument.forEach(item => x = x[item])
            x?.toString().toLowerCase().indexOf(ev.target.value.toString().toLowerCase()) > -1 && data.push(dataResult)
          })
        })
        this.servicesProvidersAll = [...data]
      }
    },
    remove(){
      httpService.delete("ServicesProvider/Delete?guid=" + this.guidDelete)
          .then(() => {
            modalService.hideModal("modal-remove");
            this.getAll();
            this.model = {};
            this.guidDelete = null;
          })
    }
  }
}
</script>
