<template>
  <div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
      <div class="content-wrapper d-flex align-items-center auth px-0">
        <div class="row w-100 mx-0">
          <div class="col-lg-4 mx-auto">
            <div class="auth-form-light text-left py-5 px-4 px-sm-5">
              <div class="brand-logo">
                <img src="../assets/images/viva-app-logo-simple.png" alt="logo">
              </div>
              <h6 class="font-weight-light">Ingrese sus datos para continuar.</h6>

              <form class="pt-3" ref="form-restore" @submit.prevent="restorePassword">
                <div class="form-group">
                  <input type="text" class="form-control"
                         name="userName"
                         placeholder="Nombre de usuario"
                         data-required="true"
                         :data-required-msg="validatorMessages().REQUIRED"
                         v-model.trim="model.userName">
                </div>
                <div class="form-group">
                  <input type="password" class="form-control"
                         name="password"
                         placeholder="Password"
                         data-required="true"
                         :data-required-msg="validatorMessages().REQUIRED"
                         v-model.trim="model.password">
                </div>
                <div class="mt-3">
                  <button type="submit" class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                          @click="restorePassword">Restaurar contraseña
                  </button>
                </div>
              </form>
            </div>
            <RouterLink to="/login" class="btn btn-link">Regresar al Login</RouterLink>
          </div>
        </div>
      </div>
      <!-- content-wrapper ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
</template>

<script>
import validatorService, {validatorMessages} from "@/services/validatorService";
import httpService from "@/services/httpService";

const fieldsToValidate = [
  "userName",
  "password",
]

export default {
  name: "RestorePasswordView",
  props:['token'],
  data:()=>({
    model:{ },
  }),
  watch: {
    ...validatorService.init(fieldsToValidate),
  },
  methods:{
    validatorMessages: ()=> validatorMessages,
    restorePassword(){
      console.log(this.token)
      if (!validatorService.validateAll(fieldsToValidate)) return;

      httpService.post(`User/ChangePassword`, {
        token    : this.token,
        newPassword : this.model.userName,
        password : btoa(this.model.password),
      })
          .then(()=> this.$router.push({name: 'home'}))
    }
  }
}
</script>

<style scoped>
.auth .brand-logo {
  margin-bottom: 0 !important;
}
</style>
