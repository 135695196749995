import { createStore } from 'vuex'
import moment from "moment";
import createPersistedState from "vuex-persistedstate";
export default createStore({
  state: {
    cities            : [],
    departments       : [],
    userLogin         : {},
    notifications     : [],
    notificationsList : [],
    agreements        : [],
    stateServiceList  : [],
    currentProfile    : {},
    profileList       : [],
    stateUserList     : [],
  },
  getters: {
    getCitiesAndDepartments: function (state){
      return {
        cities      :state.cities,
        departments :state.departments,
      }
    },
    getUserInfo: function (state){
      return state.userLogin
    },
    getNotifications: function (state){
      return state.notifications;
    },
    getAgreements: function (state){
      return state.agreements;
    },
    getNotificationsList: function (state){
      return state.notificationsList;
    },
    getStateServicesList: function (state){
      return state.stateServiceList;
    },
    getCurrentProfile: function (state){
      return state.currentProfile;
    },
    getAllProfiles: function (state){
      return state.profileList;
    },
    getAllStateUser: function (state){
      return state.stateUserList;
    },
  },
  mutations: {
    SET_CITIES_AND_DEPARTMENTS(state, citiesAndDepartments){
      state.cities      = citiesAndDepartments.cities;
      state.departments = citiesAndDepartments.departments;
    },
    SET_NOTIFICATIONS(state, notification){
      const timeMoment = moment().locale("es");console.error(notification)
      state.notifications.push({...notification, date: timeMoment.format("LLL"), time: timeMoment.unix()})
    },
    SET_AGREEMENTS(state, agreements){
      state.agreements = agreements
    },
    SET_ALL_NOTIFICATIONS(state, notifications){
      state.notifications = notifications
          ?.sort(({date: a}, {date: b}) => a < b ? 1 : a > b ? -1 : 0)
          .map(notification => ({
            ...notification,
            date: moment(notification.date).locale("es").format("LLL"),
            time: moment(notification.date).unix(),
          }))
    },
    SET_NOTIFICATIONS_LIST(state){
      state.notificationsList = state.notifications.map(x => {
        const agreement = state.agreements.find(agr => agr.guid === x.agreementGuid)
        if(agreement !== undefined) return {...x, agreement}
      })
    },
    SET_AUTH(state, authModel){
      state.userLogin = authModel
    },
    SET_STATE_SERVICES(state, list){
      state.stateServiceList = list;
    },
    SET_CURRENT_PROFILE(state, profile){
      state.currentProfile = profile;
    },
    SET_ALL_PROFILES(state, profiles){
      state.profileList = profiles;
    },
    SET_ALL_STATES_USER(state, list){
      state.stateUserList = list;
    },
  },
  actions: {
  },
  modules: {
  },
  plugins:[createPersistedState()]
})
