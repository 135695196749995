<template>
  <div class="home">
    <div class="row">
      <div class="col-md-6 mb-4 stretch-card transparent" v-if="'A4EA0EC2-C8D3-41CB-B51A-5469593C0079' === getCurrentProfile?.guid.toString().toUpperCase()">
        <router-link to="/pqr" class="w-100">
          <div class="card card-tale">
            <div class="card-body">
              <p class="mb-4">PQR</p>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-md-6 mb-4 stretch-card transparent">
        <router-link to="/notificaciones" class="w-100">
          <div class="card card-dark-blue">
            <div class="card-body">
              <p class="mb-4">Notificaciones</p>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-md-6 mb-4 stretch-card transparent" v-if="'23348E99-7FEA-4E9F-BBB6-A7F0CC9AC87D' === getCurrentProfile?.guid.toString().toUpperCase()">
        <router-link to="/historyServices" class="w-100">
          <div class="card card-light-danger">
            <div class="card-body">
              <p class="mb-4">Gestionar servicios</p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'HomeView',
  components: { },
  computed:{
    ...mapGetters(['getCurrentProfile'])
  }
}
</script>
