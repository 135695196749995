<template>
  <h4 class="card-title">¿Qué deseas hacer?</h4>
  <div class="row" style="gap: 15px;height: 60px">
    <button type="button" class="btn btn-outline-dark btn-sm btn-icon-text mb-5"
            data-toggle="modal" data-target="#modal-add-service" @click="model = {}">
      <i class='bx bx-user-plus btn-icon-prepend'></i>
      Crear nuevo servicio
    </button>
  </div>
  <div class="form-group col-sm-6 col-md-4">
    <div class="input-group">
      <input type="text" class="form-control" placeholder="Buscar..."
             @keyup="ev => filterSearch(ev, 'serviceName', 'serviceCode', 'serviceDescription')">
      <div class="input-group-append">
        <button class="btn btn-sm btn-primary" type="button"><i class="bx bx-search"></i></button>
      </div>
    </div>
  </div>
  <div v-if="servicesAll?.length > 0">
    <div class="row">
      <div class="col-sm-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <p class="card-title mb-0">Servicios</p>
            <div class="table-responsive">
              <table class="table table-striped table-borderless">
                <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Codigo</th>
                  <th>Descripcion</th>
                  <th>Icono</th>
                  <th>Activo/Inactivo</th>
                  <th>Acciones</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(service, index) in paginationData" :key="index">
                  <td >{{ service.serviceName }}</td>
                  <td >{{ service.serviceCode }}</td>
                  <td >{{ service.serviceDescription }}</td>
                  <td >{{ service.serviceIconClass }}</td>
                  <td >
                    <span class="badge badge-success" v-if="service.serviceEnable">Activo</span>
                    <span class="badge badge-danger" v-else>Inactivo</span>
                  </td>
                  <td>
                    <div class="dropdown">
                      <button class="btn btn-sm btn-danger dropdown-toggle m-0" type="button"
                              data-toggle="dropdown">
                        <i class='bx bx-wrench'></i>
                      </button>
                      <div class="dropdown-menu">
                        <h6 class="dropdown-header">¿Qué deseas hacer?</h6>
                        <button type="button" @click="servicesDetail = service" class="p-3 dropdown-item"
                                data-toggle="modal" data-target="#modal-details">
                          <i class='bx bx-search-alt align-middle'></i> &nbsp;&nbsp; Ver el servicio
                        </button>
                        <button type="button" @click="showEdit(service)" class="p-3 dropdown-item"
                                data-toggle="modal" >
                          <i class='bx bx-edit-alt align-middle'></i> &nbsp;&nbsp; Editar el servicio
                        </button>
                        <button type="button" @click="showDelete(service.guid)" class="p-3 dropdown-item"
                                data-toggle="modal" >
                          <i class='bx bx-trash align-middle'></i> &nbsp;&nbsp; Eliminar
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <a-pagination v-model:current="currentPage"
                            v-model:page-size="pageSize"
                            :show-size-changer="false"
                            :total="servicesAll.length"
                            :show-total="total => `${total} reg`"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    Sin registros para mostrar...
  </div>

  <modal-component id-modal="modal-details" title="Detalle del servicio">
    <template v-slot:modal-body>
      <div class="card">
        <div class="card-body">
          <p class="card-title">{{ servicesDetail?.serviceName }}</p>
          <ul class="icon-data-list">
            <li>
              <div class="d-flex">
                <div>
                  <p class="text-info mb-1">{{ servicesDetail?.serviceCode }}</p>
                </div>
              </div>
            </li>
            <li>
              <div class="d-flex">
                <div>
                  <p class="text-info mb-1">{{ servicesDetail?.serviceDescription }}</p>
                </div>
              </div>
            </li>
            <li>
              <div class="d-flex">
                <div>
                  <p class="text-info mb-1">{{ servicesDetail?.serviceIconClass }}</p>
                </div>
              </div>
            </li>
            <li>
              <div class="d-flex">
                <div>
                  <p class="text-info mb-1"><span class="badge badge-success" v-if="servicesDetail?.serviceEnable">Activo</span>
                    <span class="badge badge-danger" v-else>Inactivo</span></p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <template v-slot:modal-footer>
      <button type="button" class="btn btn-primary" data-dismiss="modal">Ok</button>
    </template>
  </modal-component>
  <modal-component id-modal="modal-add-service" title="Crear nuevo servicio">
    <template v-slot:modal-body>
      <form @submit.prevent="addService" ref="form-add-service">
        <div class="form-row">
          <div class="col-sm-12 ">
            <label class="form-label">Nombre del servicio</label>
            <input type="text" class="form-control"
                   name="serviceName"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model.trim="model.serviceName">
          </div>

          <div class="col-sm-12 ">
            <label class="form-label">Código del servicio</label>
            <input type="text" class="form-control"
                   name="serviceCode"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   data-number="true"
                   v-model.trim="model.serviceCode">
          </div>
          <div class="col-sm-12 ">
            <label class="form-label">Descripción del servicio</label>
            <input type="text" class="form-control"
                   name="serviceDescription"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model.trim="model.serviceDescription">
          </div>
          <div class="col-sm-12 ">
            <label class="form-label">Icono</label>
            <input type="text" class="form-control"
                   name="serviceIconClass"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model.trim="model.serviceIconClass">
          </div>
          <div class="col-sm-12">
            <label class="form-label">Estado</label>
            <select class="form-control form-control-md"
                    name="serviceEnable"
                    data-required="true"
                    :data-required-msg="validatorMessages.REQUIRED"
                    v-model.trim="model.serviceEnable">
              <option selected>Seleccione el estado...</option>
              <option value="true">Activo</option>
              <option value="false">Inactivo</option>
            </select>
          </div>
        </div>
      </form>
    </template>
    <template v-slot:modal-footer>
      <button type="submit" class="btn btn-primary" @click="addService">Ok</button>
    </template>
  </modal-component>
  <modal-component id-modal="modal-remove" title="Eliminar Servicio">
    <template v-slot:modal-body>
      <h4 class="text-center">¿En verdad desea eliminar este registro?</h4>
    </template>
    <template v-slot:modal-footer>
      <button type="button" class="btn btn-primary" @click="remove">Ok</button>
    </template>
  </modal-component>
</template>

<script>
import httpService from "@/services/httpService";
import toastService from "@/services/toastService";
import modalComponent from "@/components/ModalComponent";
import validatorService, { validatorMessages } from "@/services/validatorService";
import modalService from "@/services/modalService";
import utilService from "@/services/utilService";

const fieldsToValidate = [
  "serviceCode",
  "serviceName",
  "serviceDescription",
  "serviceIconClass",
  "serviceEnable"
]
export default {
  name       : "ServicesView",
  components :{modalComponent},
  data:()=>({
    servicesAll         :[],
    servicesOriginalAll :[],
    servicesDetail      : null,
    model               :{ },
    guidDelete          :null,
    currentPage         : 1,
    pageSize            : 10,
    validatorMessages
  }),
  computed: {
    paginationData() {
      return utilService.filterData(this.servicesAll, this.currentPage, this.pageSize)
    }
  },
  mounted() {
    this.getServicesAll()
  },
  watch: {
    ...validatorService.init(fieldsToValidate)
  },
  methods:{
    getServicesAll(){
      httpService.get("/Service/GetAll", false)
          .then(res => {
            this.servicesAll         = res?.data?.data;
            this.servicesOriginalAll = res?.data?.data;
          })
          .catch(() => toastService.error())
    },
    addService() {
      if (!validatorService.validateAll(fieldsToValidate)) return;
      const dataModel = {...this.model}
      dataModel.serviceEnable = dataModel.serviceEnable == "true" ? true : false;
      this.model.guid != undefined && this.model.guid != ''
      ?
      httpService.put("Service/Update", {...dataModel})
          .then(() => {
            modalService.hideModal("modal-add-service");
            this.getServicesAll();
          })
      :
      httpService.post("Service/Create", {...dataModel})
          .then(() => {
            modalService.hideModal("modal-add-service");
            this.getServicesAll();
          })
    },
    showEdit(service){
      console.log({service})
      this.resetForm();
      this.model = service;
      validatorService.reset(this.model);
      modalService.showModal('modal-add-service');
    },
    resetForm(){
      this.$refs["form-add-service"].reset()
    },
    showDelete(guid){
      this.guidDelete = guid;
      modalService.showModal('modal-remove');
    },
    remove() {
      httpService.delete("Service/DeleteService?guid=" + this.guidDelete)
          .then(() => {
            this.getServicesAll();
            this.guidDelete = null;
            modalService.hideModal('modal-remove');
          })
          .catch(()=> modalService.hideModal("modal-remove"));
    },
    filterSearch(ev, ...args) {
      this.servicesAll = [...this.servicesOriginalAll];
      if (ev.target.value !== "") {
        let data = []
        args.forEach(arg => {
          this.servicesOriginalAll.forEach(x => {
            const dataResult = {...x}
            const argument = arg.split(".")
            argument.forEach(item => x = x[item]);
            x?.toString().toLowerCase().indexOf(ev.target.value.toString().toLowerCase()) > -1 &&
            !data.some(y => y[arg] === x) &&
            data.push(dataResult)
          });
        });
        this.servicesAll = [...data];
      }
    }
  }
}
</script>

<style scoped>

</style>
