import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CustomerView from "@/views/CustomerView";
import PaymentView from "@/views/PaymentView";
import ProviderView from "@/views/ProviderView";
import ServicesView from "@/views/ServicesView";
import DocumentPollView from "@/views/DocumentPollView";
import UserView from "@/views/UserView";
import PqrView from "@/views/PqrView";
import ServicesHistoryView from "@/views/ServicesHistoryView";
import BankView from "@/views/BankView";
import AdvertisingView from "@/views/AdvertisingView";
import NotificationView from "@/views/NotificationView.vue";
import LoginView from "@/views/LoginView.vue";
import store from "@/store";
import ServiceProviderView from "@/views/ServiceProviderView.vue";
import RestorePasswordView from "@/views/RestorePasswordView";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/restaurar-password/:token',
    name: 'restore-password',
    props: true,
    component: RestorePasswordView
  },
  {
    path: '/clientes',
    name: 'clientes',
    component: CustomerView
  },
  {
    path: '/facturas',
    name: 'facturas',
    component: PaymentView
  },
  {
    path: '/proveedores',
    name: 'proveedores',
    component: ProviderView
  },
  {

    path: '/bancos',
    name: 'bancos',
    component: BankView
  },
  {
    path: '/publicidad',
    name: 'publicidad',
    component: AdvertisingView
  },
  {
    path: '/servicios',
    name: 'servicios',
    component: ServicesView
  },
  {
    path: '/servicios-proveedor',
    name: 'services-provider',
    component: ServiceProviderView
  },
  {
    path: '/documentos',
    name: 'documentos',
    component: DocumentPollView
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: UserView
  },
  {
    path: '/pqr',
    name: 'pqr',
    component: PqrView
  },
  {
    path: '/notificaciones/:id?',
    name: 'notifications',
    component: NotificationView,
    props:true
  },
  {
    path: '/historyServices',
    name: 'SolicitudServicios',
    component: ServicesHistoryView,
    props:true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass:"active-link"
})
router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && to.name !== 'restore-password' && Object.keys(store.state.userLogin).length === 0) next({ name: 'login' })
  else next()
})
export default router
