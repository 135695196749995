<template>
  <h4 class="card-title">¿Qué deseas hacer?</h4>
  <div class="row" style="gap: 15px;height: 60px">
    <button type="button" class="btn btn-outline-dark btn-sm btn-icon-text mb-5"
            data-toggle="modal" data-target="#modal-add-advertising">
      <i class='bx bx-user-plus btn-icon-prepend'></i>
      Crear nueva publicidad
    </button>
  </div>
  <div class="row justify-content-end">
    <div class="form-group col-sm-6 col-md-4">
      <div class="input-group">
        <input type="text" class="form-control" placeholder="Buscar..."
               @keyup="ev => filterSearch(ev, 'name', 'title', 'description', 'status',)">
        <div class="input-group-append">
          <button class="btn btn-sm btn-primary" type="button"><i class="bx bx-search"></i></button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="advertisingAll?.length > 0">
    <div class="row">
      <div class="col-sm-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <p class="card-title mb-0">Publicidad</p>
            <div class="table-responsive">
              <table class="table table-striped table-borderless">
                <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Título</th>
                  <th>Descripción</th>
                  <th>Estatus</th>
                  <th>Imagen</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(advertising, index) in paginationData" :key="index">
                  <td>{{ advertising.name }}</td>
                  <td>{{ advertising.title }}</td>
                  <td>{{ advertising.description }}</td>
                  <td>{{ advertising.status }}</td>
                  <td>
                    <img style="cursor: pointer" :src="`data:image/png;base64, ${advertising?.urlImagen}`" alt="" @click="setImg(advertising)">
                  </td>
                  <td>
                    <div class="dropdown">
                      <button class="btn btn-sm btn-danger dropdown-toggle m-0" type="button"
                              data-toggle="dropdown">
                        <i class='bx bx-wrench'></i>
                      </button>
                      <div class="dropdown-menu">
                        <h6 class="dropdown-header">¿Qué deseas hacer?</h6>

                        <button type="button" @click="showAdvertising(advertising)" class="p-3 dropdown-item"
                                data-toggle="modal" >
                          <i class='bx bx-search-alt align-middle'></i> &nbsp;&nbsp; Editar publicidad
                        </button>
                        <button type="button" @click="advertisingDetail = advertising" class="p-3 dropdown-item"
                                data-toggle="modal" data-target="#modal-remove-advertising">
                          <i class='bx bx-trash align-middle'></i> &nbsp;&nbsp; Eliminar publicidad
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <br/>
              <a-pagination v-model:current="currentPage"
                            v-model:page-size="pageSize"
                            :show-size-changer="false"
                            :total="advertisingAll.length"
                            :show-total="total => `${total} reg`"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    Sin registros para mostrar...
  </div>
  <modal-component id-modal="modal-add-advertising" title="Agregar publicidad">
    <template v-slot:modal-body>
      <form @submit.prevent="addAdvertising" ref="form-add-advertising">
        <div class="form-row">
          <div class="col-sm-12 col-md-6 mb-3">
            <label class="form-label">Nombre</label>
            <input type="text" class="form-control"
                   name="name"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model.trim="model.name">
          </div>
          <div class="col-sm-12 col-md-6 mb-3">
            <label class="form-label">Título</label>
            <input type="text" class="form-control"
                   name="title"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model.trim="model.title">
          </div>
          <div class="col-sm-12 col-md-6 mb-3">
            <label class="form-label">Descripción</label>
            <input type="text" class="form-control"
                   name="description"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model.trim="model.description">
          </div>
          <div class="col-sm-12 col-md-6 mb-3">
            <label class="form-label">Teléfono</label>
                <input type="text"
                   class="form-control"
                   name="contactPhone"
                   data-required="false"
                   :data-required-msg="validatorMessages.REQUIRED"
                   data-number="true"
                   :data-number-msg="validatorMessages.NUMBER"
                   v-model="model.contactPhone">
          </div>
          <div class="col-sm-12 mb-3">
            <div class="mb-3">
              <label for="formFile" class="form-label">Imagen</label>
              <input class="form-control"
                     type="file"
                     id="img-ads"
                     accept="image/*"
                     data-required="true"
                     :data-required-msg="validatorMessages.REQUIRED"
                     @change="setImgToModel">

              <input type="text" class="form-control"
                     name="urlImagen"
                     hidden
                     data-required="true"
                     :data-required-msg="validatorMessages.REQUIRED"
                     v-model.trim="model.urlImagen">
            </div>
          </div>
          <div class="col-sm-12 mb-3">
            <label class="form-label">Url WebSite</label>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class='bx bx-globe' ></i></span>
                </div>
                <input type="text" class="form-control"
                   name="urlWebSite"
                   data-required="true"
                   v-model.trim="model.urlWebSite">
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
    <template v-slot:modal-footer>
      <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
      <button type="submit" class="btn btn-primary" @click="addAdvertising">Ok</button>
    </template>
  </modal-component>
  <modal-component id-modal="modal-remove-advertising" title="Eliminar Publicidad">
    <template v-slot:modal-body>
      <h4 class="text-center">¿En verdad desea eliminar este registro?</h4>
    </template>
    <template v-slot:modal-footer>
      <button type="button" class="btn btn-primary" @click="removeAdvertising">Ok</button>
    </template>
  </modal-component>
  <FsLightbox
      :toggler="toggle"
      :sources="[`data:image/png;base64, ${advertisingDetail?.urlImagen}`]"
  />
</template>

<script>
import httpService from "@/services/httpService";
import toastService from "@/services/toastService";
import modalComponent from "@/components/ModalComponent";
import FsLightbox from "fslightbox-vue/v3";
import validatorService, { validatorMessages, regexValidator } from "@/services/validatorService";
import modalService from "@/services/modalService";
import utilService from "@/services/utilService";

const fieldsToValidate = [
  "name",
  "title",
  "description",
  "urlImagen",
  "contactPhone"
]

export default {
  name: "AdvertisingView",
  data:()=>({
    advertisingAll         :[],
    advertisingOriginalAll :[],
    advertisingDetail      : null,
    model                  :{ urlImagen:null },
    toggle                 : false,
    currentPage            : 1,
    pageSize               : 10,
    validatorMessages,
    regexValidator
  }),
  components:{modalComponent, FsLightbox},
  mounted() {
    this.getAdvertisingAll()
  },
  computed:{
    paginationData(){
      return utilService.filterData(this.advertisingAll, this.currentPage, this.pageSize)
    }
  },
  watch:{
    ...validatorService.init(fieldsToValidate)
  },
  methods:{
    resetForm(){
      this.$refs["form-add-advertising"].reset()
    },
    getAdvertisingAll(){
      httpService.get("/Advertising/GetAll", false)
          .then(res => {
            this.advertisingAll         = res?.data?.data;
            this.advertisingOriginalAll = res?.data?.data;
          })
          .catch(() => toastService.error())
    },
    setImg(advertising){
      this.advertisingDetail = advertising
      this.toggle = !this.toggle
    },
    addAdvertising(){
      if (!validatorService.validateAll(fieldsToValidate)) return;
      this.setImgToModel();
      const dataModel = {...this.model}
      const flagUpdate = this.model.guid !== undefined && this.model.guid !== ''
      const options = {
        method   :  flagUpdate ? httpService.types.PUT : httpService.types.POST,
        action : flagUpdate ? "/Advertising/Update" : "/Advertising/Create",
        data   : dataModel
      }
      httpService.execute(options)
          .then(() => {
            modalService.hideModal("modal-add-advertising");
            this.getAdvertisingAll();
            this.model = {};
          })
          .catch(({response}) => toastService.error(response?.message))
    },
    showAdvertising(advertising){
      this.resetForm()
      modalService.showModal('modal-add-advertising');
      this.model = advertising;
      validatorService.reset(this.model)
    },
    removeAdvertising(){
      httpService.delete("Advertising/Delete?guid=" + this.advertisingDetail.guid)
          .then(() => {
            modalService.hideModal("modal-remove-advertising");
            this.getBankAll();
            this.model = {};
          })
    },
    setImgToModel(){
      const file = document.getElementById('img-ads').files[0];
      if (file.size > 1024000){
        toastService.error("Tamaño de imagen no permitido, máximo 1MB");
        return;
      }
      this.convertImgToBase64(file);
    },
    convertImgToBase64(file){
      const self = this;
      let base64String = "";
      let reader = new FileReader();
      reader.onload = function () {
        base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
        self.model.urlImagen = base64String;
      }
      reader.readAsDataURL(file);
    },
    filterSearch(ev, ...args) {
      this.advertisingAll = [...this.advertisingOriginalAll];
      if (ev.target.value !== "") {
        let data = []
        args.forEach(arg => {
          this.advertisingOriginalAll.forEach(x => {
            const dataResult = {...x}
            const argument = arg.split(".")
            argument.forEach(item => x = x[item]);
            x?.toString().toLowerCase().indexOf(ev.target.value.toString().toLowerCase()) > -1 &&
            data.push(dataResult)
          });
        });
        this.advertisingAll = Array.from(new Set(data.map(JSON.stringify))).map(JSON.parse);
      }
    },
  },
}
</script>
