<template>
  <h4 class="card-title">¿Qué deseas hacer?</h4>
  <div class="row" style="gap: 15px;height: 60px">
    <button type="button" class="btn btn-outline-dark btn-sm btn-icon-text mb-5"
            data-toggle="modal" data-target="#modal-add-user" @click="resetForm">
      <i class='bx bx-user-plus btn-icon-prepend'></i>
      Crear nuevo usuario
    </button>
  </div>
  <div class="row justify-content-end">
    <div class="form-group col-sm-6 col-md-4">
      <div class="input-group">
        <input type="text" class="form-control" placeholder="Buscar..."
               @keyup="ev => filterSearch(ev, 'firstName', 'secondName', 'firstSurname', 'mobile', 'email')">
        <div class="input-group-append">
          <button class="btn btn-sm btn-primary" type="button"><i class="bx bx-search"></i></button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="userAll?.length > 0">
    <div class="row">
      <div class="col-sm-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <p class="card-title mb-0">Usuarios</p>
            <div class="table-responsive">
              <table class="table table-striped table-borderless">
                <thead>
                <tr>
                  <th>Nombres</th>
                  <th>Teléfono</th>
                  <th>e-mail</th>
                  <th>Acciones</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(user, index) in paginationData" :key="index">
                  <td>{{ user.firstName }} {{ user?.secondName }} {{ user?.firstSurname }}
                    {{ user?.secondSurname }}
                  </td>
                  <td>{{ user.mobile }}</td>
                  <td>{{ user.email }}</td>
                  <td>
                    <div class="dropdown">
                      <button class="btn btn-sm btn-danger dropdown-toggle m-0" type="button"
                              data-toggle="dropdown">
                        <i class='bx bx-wrench'></i>
                      </button>
                      <div class="dropdown-menu">
                        <h6 class="dropdown-header">¿Qué deseas hacer?</h6>

                        <button type="button" @click="userDetail = user" class="p-3 dropdown-item"
                                data-toggle="modal" data-target="#modal-details">
                          <i class='bx bx-search-alt align-middle'></i> &nbsp;&nbsp; Ver usuario
                        </button>
                        <button type="button" @click="()=> {
                          resetForm();
                          showEdit(user);
                        }" class="p-3 dropdown-item">
                          <i class='bx bx-edit-alt'></i> &nbsp;&nbsp; Editar usuario
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <a-pagination v-model:current="currentPage"
                            v-model:page-size="pageSize"
                            :show-size-changer="false"
                            :total="userAll.length"
                            :show-total="total => `${total} reg`"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    Sin registros para mostrar...
  </div>

  <modal-component id-modal="modal-details" title="Detalle del cliente">
    <template v-slot:modal-body>
      <div class="card">
        <div class="card-body">
          <p class="card-title">{{ userDetail?.firstName }} {{ userDetail?.secondName }}
            {{ userDetail?.firstSurname }} {{ userDetail?.secondSurname }}</p>
          <ul class="icon-data-list">
            <li>
              <div class="d-flex">
                <img :src="`/images/${userDetail?.gender === 'M' ? 'hombre':'mujer'}.png`" alt="user">
                <div>
                  <p class="text-info mb-1">{{ userDetail?.identificationType }} -
                    {{ userDetail?.identification }}</p>
                  <i class='bx bx-phone align-middle'></i><small>{{ userDetail?.mobile }}</small> -
                  <small>{{ userDetail?.phone }}</small><br/>
                  <i class='bx bx-envelope align-middle'></i><small> {{ userDetail?.email }}</small><br/>
                  <i class='bx bxs-briefcase align-middle'></i>&nbsp;<i class='bx bx-phone align-middle'></i><small>Teléfono
                  trabajo {{ userDetail?.phoneWork }}</small><br/>
                  <i class='bx bx-location-plus'></i><small> Dirección trabajo:
                  {{ userDetail?.addressWork }}</small><br/>
                  <i class='bx bx-street-view'></i><small>
                  {{ userDetail?.gender === 'M' ? 'Masculino' : 'Femenino' }}</small><br/>
                  <i class='bx bx-buildings'></i><small> Ciudad de nacimiento:
                  {{ getCityForDetail(userDetail?.cityBirth)}}</small><br/>
                  <i class='bx bx-buildings'></i><small> Departamento de nacimiento:
                  {{ getDepartmentsForDetail( userDetail?.departamentBirth )}}</small><br/>
                  <i class='bx bxs-baby-carriage'></i><small> Fecha de nacimiento:
                  {{ dateTimeService.formatDate(userDetail?.dateOfBirth?.toString(), dateTimeService.formats.spanish) }}</small><br/>
                  <i class='bx bx-calendar-event'></i><small> Fecha de creación en sistema:
                  {{ dateTimeService.formatDate(userDetail?.create?.toString(), dateTimeService.formats.spanish) }}</small><br/>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <template v-slot:modal-footer>
      <button type="button" class="btn btn-primary" data-dismiss="modal">Ok</button>
    </template>
  </modal-component>

  <modal-component id-modal="modal-add-user" title="Crear/Editar nuevo usuario" size="lg">
    <template v-slot:modal-body>
      <form @submit.prevent="addUser" id="form-add-user" ref="form-add-user">
        <div class="form-row">
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Número identificación</label>
            <input type="text"
                   class="form-control"
                   name="identification"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model="model.identification">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Tipo de identificación</label>
            <select class="form-control form-control-md"
                    name="identificationType"
                    data-required="true"
                    :data-required-msg="validatorMessages.REQUIRED"
                    v-model="model.identificationType">
              <option selected disabled>Seleccione Tipo de identificación...</option>
              <option value="CC">Cédula de ciudadanía</option>
              <option value="CE">Cédula de extranjería</option>
              <option value="TI">Tarjeta de identidad</option>
              <option value="PA">Pasaporte</option>
              <option value="RC">Registro Civil</option>
              <option value="OT">Desconocido</option>
              <option value="NUIP">Numero Unico de Identificación Personal</option>
              <option value="NI">NIT</option>
            </select>
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Primer nombre</label>
            <input type="text"
                   class="form-control"
                   name="firstName"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model="model.firstName">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Segundo nombre</label>
            <input type="text"
                   class="form-control"
                   name="secondName"
                   data-required="false"
                   v-model="model.secondName">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Primer apellido</label>
            <input type="text"
                   class="form-control"
                   name="firstSurname"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model="model.firstSurname">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Segundo apellido</label>
            <input type="text"
                   class="form-control"
                   name="secondSurname"
                   data-required="false"
                   v-model="model.secondSurname">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Número teléfono personal</label>
            <input type="text"
                   class="form-control"
                   name="phone"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   data-number="true"
                   :data-number-msg="validatorMessages.NUMBER"
                   v-model="model.phone">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Número teléfono trabajo</label>
            <input type="text"
                   class="form-control"
                   name="phoneWork"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   data-number="true"
                   :data-number-msg="validatorMessages.NUMBER"
                   v-model="model.phoneWork">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Número celular personal</label>
            <input type="text"
                   class="form-control"
                   name="mobile"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   data-number="true"
                   :data-number-msg="validatorMessages.NUMBER"
                   v-model="model.mobile">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Correo</label>
            <input type="text"
                   class="form-control"
                   name="email"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   data-regex="^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$"
                   :data-regex-msg="validatorMessages.EMAIL"
                   v-model.trim="model.email">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Dirección trabajo</label>
            <input type="text"
                   class="form-control"
                   name="addressWork"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model.trim="model.addressWork">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Género</label>
            <select class="form-control form-control-md"
                    name="gender"
                    data-required="true"
                    :data-required-msg="validatorMessages.REQUIRED"
                    v-model.trim="model.gender">
              <option selected disabled>Seleccione género...</option>
              <option value="M">Masculino</option>
              <option value="F">Femenino</option>
            </select>
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Departamento de nacimiento</label>
            <select class="form-control form-control-md"
                    name="departamentBirth"
                    data-required="true"
                    :data-required-msg="validatorMessages.REQUIRED"
                    v-model="model.departamentBirth">
              <option selected>Seleccione departamento...</option>
              <option v-for="(department, key) in getCitiesAndDepartments.departments"
                      :key="key"
                      :value="department">
                {{department?.name}}
              </option>
            </select>
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Ciudad de nacimiento</label>
            <select class="form-control form-control-md"
                    name="cityBirth"
                    data-required="true"
                    :data-required-msg="validatorMessages.REQUIRED"
                    v-model="model.cityBirth">
              <option selected value="">Seleccione ciudad...</option>
              <option v-for="(city, key) in getCitiesAndDepartments.cities.filter(x => x.codeStat === model?.departamentBirth?.code)"
                      :value="city.code"
                      :key="key">
                {{city?.name}}
              </option>
            </select>
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Fecha nacimiento</label>
            <input type="date"
                   class="form-control"
                   name="dateOfBirth"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model.trim="model.dateOfBirth">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Fecha expedición de documento</label>
            <input type="date" class="form-control"
                   name="dateDocumentIssuance"
                   data-required="true"
                   :data-required-msg="validatorMessages.REQUIRED"
                   v-model.trim="model.dateDocumentIssuance">
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Perfil</label>
            <select class="form-control form-control-md"
                    name="profileGuid"
                    data-required="true"
                    :data-required-msg="validatorMessages.REQUIRED"
                    v-model="model.profileGuid">
              <option  selected>Seleccione perfil...</option>
              <option v-for="(profile, key) in getAllProfiles"
                      :value="profile.guid"
                      :key="key">
                {{profile?.name}}
              </option>
            </select>
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label class="form-label">Estado</label>
            <select class="form-control form-control-md"
                    name="userStateGuid"
                    data-required="true"
                    :data-required-msg="validatorMessages.REQUIRED"
                    v-model="model.userStateGuid">
              <option selected disabled>Seleccione estado...</option>
              <option v-for="(state, key) in getAllStateUser"
                      :value="state.guid"
                      :key="key">
                {{state?.name}}
              </option>
            </select>
          </div>
        </div>
      </form>
    </template>
    <template v-slot:modal-footer>
      <button type="submit" class="btn btn-primary" @click="addUser">Ok</button>
    </template>
  </modal-component>
</template>

<script>
import httpService from "@/services/httpService";
import ModalComponent from "@/components/ModalComponent";
import dateTimeService from "@/services/dateTimeService";
import toastService from "@/services/toastService";
import modalService from "@/services/modalService";
import {mapGetters} from "vuex"
import validatorService, {validatorMessages} from "@/services/validatorService";
import utilService from "@/services/utilService";

const fieldsToValidate = [
  "identification",
  "identificationType",
  "firstName",
  "firstSurname",
  "phone",
  "phoneWork",
  "mobile",
  "email",
  "addressWork",
  "gender",
  "dateOfBirth",
  "dateDocumentIssuance",
  "profileGuid",
  "userStateGuid",
]

export default {
  name: "UserView",
  components: { ModalComponent },
  data:()=>({
    userAll         :[],
    userOriginalAll :[],
    userDetail      : null,
    model           :{ },
    currentPage     : 1,
    pageSize        : 10,
    validatorMessages,
    dateTimeService,
  }),
  computed:{
    ...mapGetters(['getCitiesAndDepartments', 'getAllProfiles', 'getAllStateUser']),
      paginationData(){
        return utilService.filterData(this.userAll, this.currentPage, this.pageSize)
      }
    },
  mounted() {
    this.getUserAll()
  },
  watch: {
    ...validatorService.init(fieldsToValidate),
  },
  methods:{
    getUserAll(){
      httpService.get("/UserAdmin/GetAll", false)
          .then(res => {
            this.userAll         = res?.data?.data;
            this.userOriginalAll = res?.data?.data;
          })
          .catch(() => toastService.error())
    },
    resetForm(){
      [...document.querySelectorAll(".text-danger.float-start.d-block")].forEach(item => item.remove());
      [...document.querySelectorAll(".is-invalid")].forEach(item => item.classList.remove("is-invalid"));
      this.$refs["form-add-user"].reset()
    },
    addUser() {
      if (!validatorService.validateAll(fieldsToValidate)) return;
      const user = { ...this.model }
      user.departamentBirth = user.departamentBirth.code
      user.registerNumber = user.identification
      const flagUpdate = this.model.guid !== undefined && this.model.guid !== ''
      const options = {
        method   :  flagUpdate ? httpService.types.PUT : httpService.types.POST,
        action : flagUpdate ? "/UserAdmin/Update" : "/UserAdmin/Create",
        data   : user
      }
      httpService.execute(options)
          .then(() => {
            modalService.hideModal("modal-add-user");
            this.getUserAll();
            this.model = {}
          })
    },
    showEdit(user){
      modalService.showModal('modal-add-user');
      this.model = {
        ...user,
        departamentBirth     : this.getCitiesAndDepartments.departments.find(x => x.code === user.departamentBirth),
        dateOfBirth          : user.dateOfBirth.split('T')[0],
        dateDocumentIssuance : user.dateDocumentIssuance.split('T')[0],
      };
      validatorService.reset(this.model)
    },
    filterSearch(ev, ...args) {
      this.userAll = [...this.userOriginalAll];
      if (ev.target.value !== "") {
        let data = []
        args.forEach(arg => {
          this.userOriginalAll.forEach(x => {
            const dataResult = {...x}
            const argument = arg.split(".")
            argument.forEach(item => x = x[item]);
            x?.toString().toLowerCase().indexOf(ev.target.value.toString().toLowerCase()) > -1 &&
            data.push(dataResult)
          });
        });
        this.userAll = Array.from(new Set(data.map(JSON.stringify))).map(JSON.parse);
      }
    },
    getCityForDetail(code){
      console.log({code:code,cities :this.getCitiesAndDepartments})
      let city = this.getCitiesAndDepartments.cities.find(x => x.code === code) ;
      console.log({city:city})
      return city?.name || "";
    },
    getDepartmentsForDetail(code){
      console.log({code:code,departmens :this.getCitiesAndDepartments})
      let departmen = this.getCitiesAndDepartments.departments.find(x => x.code == code);
      console.log({departmen:departmen})
      return departmen?.name || "";
    }
  }
}
</script>
